<template>
    <div>
        <div class="wrap-start-header pl16 pr16">
            <button class="btn-header back" @click="BackEvt()"></button>
            <div class="title-header">
                {{
                $t(headerTitle ? headerTitle : $route.meta.title)
                }}
            </div>
        </div>
        <event-list-component :event-type="'eventSearch'"></event-list-component>
    </div>
</template>


<script>
import { mapState } from "vuex";
import memberMixins from "@/mixins/memberMixins";
import PageHeader from "@/components/PageHeader.vue";
import EventListComponent from "@/components/event/EventListComponent.vue";

export default {
    name: "EventSearch",
    mixins: [memberMixins],
    components: {
        PageHeader,
        EventListComponent,
    },
    props: {},
    data() {
        return {
            userType: "",
            ongoingEmpty: false,
            upcommingEmpty: false,
            pastEmpty: false,
            allEmpty: true,
        };
    },
    beforeRouterEnter() {},
    created() {},
    mounted() {},
    beforeUnmount() {},
    unmounted() {},
    computed: {
        ...mapState({
            UserInfo: (state) => state.userInfo.UserInfo,
        }),
    },
    methods: {
        checkDataEmpty() {
            if (
                this.userType === "normal" &&
                this.ongoingEmpty === true &&
                this.upcommingEmpty === true
            ) {
                return false;
            } else {
                return true;
            }
        },
    },
    watch: {},
};
</script>
