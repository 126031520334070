var render = function render(){var _vm=this,_c=_vm._self._c;return _c('svg',{ref:"svg",staticClass:"svg-ticket",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":_vm.svgWidth,"height":_vm.svgHeight}},[_c('path',{ref:"pathLeft",attrs:{"d":`M ${_vm.radius} 0
            H ${_vm.leftSvgWidth - _vm.radius}
            A ${_vm.radius} ${_vm.radius} 0 0 0 ${_vm.leftSvgWidth} ${_vm.radius}
            V ${_vm.svgHeight - _vm.radius}
            A ${_vm.radius} ${_vm.radius} 0 0 0 ${_vm.leftSvgWidth - _vm.radius} ${_vm.svgHeight}
            H ${_vm.radius}
            A ${_vm.radius} ${_vm.radius} 0 0 1 0 ${_vm.svgHeight - _vm.radius}
            V ${_vm.radius}
            A ${_vm.radius} ${_vm.radius} 0 0 1 ${_vm.radius} 0
            Z`,"fill":_vm.ticketLeftColor}}),_c('path',{ref:"pathRight",attrs:{"d":`
            M ${_vm.leftSvgWidth + _vm.radius} 0
            H ${_vm.svgWidth - _vm.radius}
            A ${_vm.radius} ${_vm.radius} 0 0 1 ${_vm.svgWidth} ${_vm.radius}
            V ${_vm.svgHeight - _vm.radius}
            A ${_vm.radius} ${_vm.radius} 0 0 1 ${_vm.svgWidth - _vm.radius} ${_vm.svgHeight}
            H ${_vm.leftSvgWidth + _vm.radius}
            A ${_vm.radius} ${_vm.radius} 0 0 0 ${_vm.leftSvgWidth} ${_vm.svgHeight - _vm.radius}
            V ${_vm.radius}
            A ${_vm.radius} ${_vm.radius} 0 0 0 ${_vm.leftSvgWidth + _vm.radius} 0
            Z
        `,"fill":_vm.ticketRightColor}})])
}
var staticRenderFns = []

export { render, staticRenderFns }