<template>
  <div class="header" id="header" :class="{'drop': scrollStatus}">
    <!-- 헤더 -->
    <div class="header_wrap">
      <div class="header_logo" @click="movePage('/')">
        <img src="@/assets/image/main/logo.png" alt="">
      </div>
      <div class="search_box">
        <div class="search_bar">
          <input type="text" @keyup.enter="searchMain()" @input="setData('searchData',searchData)"
                 v-model="searchData" class="search_input"/>
          <button class="search_icon" @click="searchMain()"></button>
        </div>
        <div class="right_menu" v-if="isLogin()">
          <button class="enroll_btn mr10" @click="autoMintingLogin" v-if="checkLaunch()">{{ $t('go_to_purchase') }}</button>

          <button class="enroll_btn" @click="movePage('/enroll')" v-if="UserInfo.g_idx===1">{{ $t('enroll') }}
          </button>
          <button class="alarm_btn" @click="movePage('/alarm')">
            <el-badge :value="'N'" class="alarm_btn_badge" v-if="UserInfo.IsAlarm"></el-badge>
          </button>
          <el-dropdown trigger="click">
            <el-button class="mypage_btn" :style="` background-image: url(${returnThumbnail()});`">
            </el-button>
            <el-dropdown-menu slot="dropdown" class="mypage_drop_down">
              <el-dropdown-item v-if="UserInfo.g_idx != 8" @click.native="movePage('/myprofile')">{{
                  $t('my_profile')
                }}
              </el-dropdown-item>
              <el-dropdown-item @click.native="moveMyPage()">{{ $t('mypage') }}</el-dropdown-item>
              <el-dropdown-item @click.native="movePage('/notice')">{{
                  $t('service_center')
                }}
              </el-dropdown-item>
              <el-dropdown-item @click.native="logout()">{{ $t('logout') }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="right_menu" v-else>
          <span @click="movePage('/auth')">{{ $t('login') }} </span>
          | <span @click="movePage('/auth/register')"> {{ $t('register') }}</span>
        </div>
        <div style="clear: both"></div>
      </div>
    </div>
    <div class="header_menu_border">
      <div class="header_menu_wrap">
        <div class="header_menu">
          <!--                    <button class="menu_btn" :class="{active: returnActive('/premiummarket')}"-->
          <!--                            @click="movePage('/premiummarket?tab=0')">-->
          <!--                        <span>Premium</span>-->
          <!--                    </button>-->
          <button class="menu_btn" @click="movePage('/openmarket?tab=178')">{{ $t('prompt') }}
          </button>
          <button class="menu_btn"
                  :class="{active: returnActive('/openmarket') || returnActive('/license')}"
                  @click="movePage('/openmarket?tab=0')">{{ $t('openMarket') }}
          </button>
          <button class="menu_btn"
                  :class="{active: returnActive('/competition') || returnActive('/entry') || returnActive('/award')}"
                  @click="movePage('/competition')">{{ $t('competition') }}
          </button>
          <button class="menu_btn" :class="{active: returnActive('/creator')}"
                  @click="movePage('/creator')">{{ $t('creator') }}
          </button>
        </div>
        <div class="flag_menu">
          <el-select class="select_country" id="flag" v-model="selectCountry"
                     @change="changeCountry(selectCountry)"
                     popper-class="select_country" :popper-append-to-body="false">
            <el-option :value="'ko'" :label="$t('Korean_kr')"></el-option>
            <el-option :value="'en'" :label="$t('English_us')"></el-option>
          </el-select>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {logout} from "@/mixins/Auth";
import {mapState} from "vuex";
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import EventBus from "@/utils/event-bus";
import AuthMixins from "@/mixins/AuthMixins";
import imageResizeMixins from "@/mixins/imageResizeMixins";
import {isDev} from "@/api/config";

export default {
  name: "PageHeader",
  mixins: [imageOption, AuthMixins, imageResizeMixins],
  components: {},
  inject: [],
  provide() {
    return {}
  },
  props: {},
  data() {
    return {
      selectCountry: 'ko',
      searchData: '',
      scrollStatus: false,
    }
  },
  beforeRouterEnter() {
  },
  created() {
  },
  mounted() {
    this.initData();
    EventBus.$on('PageHeaderForceUpdate', this.PageHeaderForceUpdate);
    let header = document.getElementById("header");
    let sticky = header.offsetTop;
    let _this = this
    window.onscroll = function () {
      if (window.pageYOffset > sticky) {
        _this.scrollStatus = true;
      } else {
        _this.scrollStatus = false;
      }
    };
  },
  beforeDestroy() {
    EventBus.$off('PageHeaderForceUpdate');
  },
  destroyed() {
  },
  computed: {
    ...mapState({
      Country: state => state.country.Country,
    })
  },
  methods: {
    initData() {
      let lang = this.$localStorage.get('lang');
      this.selectCountry = lang;
      if (util.isEmpty(lang)) {
        this.selectCountry = this.Country;
      }
    },
    movePage(url) {
      if (this.$route.path.includes('/openmarket') && url.includes('/openmarket')) {
        this.$router.replace(url);
        this.$router.go();
      } else {
        this.$router.push(url);
      }

    },
    changeCountry(country) {
      this.$store.dispatch('country/changeCountry', {data: country});
      this.changeLang(country);
    },
    changeLang(lang) {
      this.$localStorage.set('lang', lang);
      this.$i18n.locale = lang;
      this.$forceUpdate();
    },
    logout() {
      logout();
    },
    returnThumbnail() {
      if (!util.isEmpty(this.UserInfo.Files) && !util.isEmpty(this.UserInfo.Files.Profile)) {
        return `${this.UserInfo.Files.Profile[0].org_url}?${this.setImageOptions(43, 43, this.returnExtension(this.UserInfo.Files.Profile[0].org_url))}`;
      }

    },
    setData(key, value) {
      this[key] = value
    },
    searchMain() {
      let url = this.$route.path
      if (url === '/search') {
        EventBus.$emit('searchGetList', this.searchData)
      }
      if (!util.isEmpty(this.searchData)) {
        this.movePage(`/search?sd=${this.searchData}`)
      }
    },
    moveMyPage() {
      if (this.getMemberType() === 'curator') {
        this.movePage('/mypage/curation')
      } else {
        this.movePage('/mypage/item')
      }
    },
    returnActive(name) {
      return this.$route.path.includes(name) && this.$route.path.indexOf(name) === 0
    },

    PageHeaderForceUpdate() {
      this.$forceUpdate()
    },
    autoMintingLogin() {
      let blockchain_weptoken = this.$localStorage.get('token');
      let url = 'http://115.68.199.206/automintLogin.do';
      if (isDev == true) {
        url = 'http://115.68.199.206/login.do'
      }
      let loginForm = $('<form></form>');
      loginForm.attr("name", "loginForm");
      loginForm.attr("method", "post");
      loginForm.attr("action", url);
      loginForm.attr("target", "_blank");

      loginForm.append($('<input/>', {
        type: 'hidden',
        name: 'com.dgmonglab.blockchain_weptoken',
        value: blockchain_weptoken
      }));

      loginForm.appendTo('body');
      loginForm.submit();
    },
    checkLaunch() {
      return !util.isEmpty(this.UserInfo) && this.UserInfo.mb_premium === 2;
    }

  },
  watch: {
    $route(to, from) {
      if (to.path != from.path) {
        this.searchData = '';
      }
    }

  },
}
</script>

<style scoped>

</style>
