<template>
    <div class="container-filter pb16 pt44">
        <div class="btn-category-common pb40">
            <div class="wrap-btn-category">
                <div class="gradiant-btn-category"></div>
                <div class="box-btn-category">
                    <button
                        class="btn-category"
                        :class="{'active' : isActive(item.ec_idx)}"
                        v-for="(item, index) in eventCategoryList"
                        :key="`categoryFilter${index}`"
                        @click="setFilterCategory(item.ec_idx)"
                    >{{ item.ec_name }}</button>
                </div>
            </div>
        </div>
        <div class="box-flex">
            <button
                class="button-filter-event active mr12"
                @click="showSortModal()"
            >
                <span>{{ returnSortName() }}</span>
                <div class="button-sort-event"></div>
            </button>
            <button
                class="button-filter-event mr12"
                :class="{'active' : returnUsest()}"
                @click="showFilterModal('event')"
            >
                <span>{{ returnUsestText() }}</span>
                <div class="button-sort-event"></div>
            </button>
            <button
                class="button-filter-event mr12"
                :class="{'active' : returnSellst()}"
                @click="showFilterModal('nticket')"
            >
                <span>{{ returnSellstText() }}</span>
                <div class="button-sort-event"></div>
            </button>
        </div>
    </div>
</template>


<script>
import eventUtil from "@/components/event/eventUtil";
import EventBus from "@/utils/event-bus";
import util from "@/mixins/util";

const { filterConfig } = eventUtil;
export default {
    name: "EventFilterCategoryLayout",
    mixins: [],
    inject: ["setFilter", "setMultiFilter", "setSort"],
    components: {},
    props: {
        filterData: {
            default: () => {
                return {};
            },
        },
        eventCategoryList: {
            default: () => {
                return [];
            },
        },
        sortData: {},
    },
    data() {
        return {
            searchInput: "",
            filterCategory: [],
            filterStartPrice: "",
            filterEndPrice: "",
            usest: [],
            sellst: [],
        };
    },
    beforeRouterEnter() {},
    created() {},
    mounted() {
        EventBus.$on("setEventFilterModal", this.setEventFilterModal);
        if (!util.isEmpty(this.$route.query.key)) {
            this.setFilterCategory(Number(this.$route.query.key));
        }

        EventBus.$on("setSortModal", this.setSortModal);
    },
    beforeUnmount() {
        EventBus.$off("setEventFilterModal");
        EventBus.$off("setSortModal");
    },
    unmounted() {},
    computed: {},
    methods: {
        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },
        setFilterCategory(key) {
            if (!this.filterCategory.includes(key)) {
                this.filterCategory.push(key);
            } else {
                this.filterCategory = this.filterCategory.filter(
                    (el) => el !== key
                );
            }
            this.setFilterFunc("eca", this.filterCategory);
        },
        setEventFilterModal(key) {
            this.usest = key.usest;
            this.sellst = key.sellst;

            let params = {
                eperArr: key.usest,
                nperArr: key.sellst,
            };
            this.setMultiFilter(params);
        },
        isActive(key) {
            return this.filterCategory.includes(key);
        },
        showFilterModal(title) {
            let data = {
                usest: this.usest,
                sellst: this.sellst,
            };

            this.$modal.show("event-normal-filter-modal", {
                filterData: data,
                titleData: title,
            });
        },

        setSortModal(data) {

            this.setSort(data.obt, data.obs);
        },

        showSortModal() {
            this.$modal.show("non-button-update-sort-modal", {
                sortData: this.sortData,
            });
        },

        returnSortName() {
            switch (this.sortData.key) {
                case "e.updated_at":
                    return this.$t("b_update");
                case "e.e_start_date":
                    return this.$t("sort_event_closed");
                case "u.u_publish_total":
                    return this.$t("sort_event_lot");
                default:
                    return this.$t("b_update");
            }
        },

        returnSellst() {
            return ["1", "2", "3"].some((value) => this.sellst.includes(value));
        },

        returnUsest() {
            return ["1", "2", "3"].some((value) => this.usest.includes(value));
        },

        returnSellstText() {
            let result = "";

            if (this.sellst.includes("1")) {
                result += this.$t("sell_stay") + ", ";
            }
            if (this.sellst.includes("2")) {
                result += this.$t("sell_ing") + ", ";
            }
            if (this.sellst.includes("3")) {
                result += this.$t("sell_end") + ", ";
            }

            return result ? result.slice(0, -2) : this.$t('all');
        },

        returnUsestText() {
            let result = "";

            if (this.usest.includes("1")) {
                result += this.$t("intended") + ", ";
            }
            if (this.usest.includes("2")) {
                result += this.$t("progress") + ", ";
            }
            if (this.usest.includes("3")) {
                result += this.$t("terminated") + ", ";
            }

            return result ? result.slice(0, -2) : this.$t('all');
        },
    },
    watch: {},
};
</script>
