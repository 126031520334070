<template>
    <div>
        <event-filter-link
            :filterLayout="filterLayout"
            :filterData="filterSet.filter"
            :paginationData="filterSet.page"
            :eventCategoryList="eventCategoryList"
            :sortData="filterSet.sort"
        ></event-filter-link>
        <event-list-link
            :listLayout="listLayout"
            :eventData="List"
            :eventType="eventType"
            :paginationData="filterSet.page"
            v-if="List.length > 0"
        ></event-list-link>
        <event-empty-link :emptyLayout="emptyLayout" :eventStatus="eventStatus" v-else></event-empty-link>
        <pagination-link
            :paginationLayout="paginationLayout"
            :paginationData="filterSet.page"
            :listLength="List.length"
            :finishGetList="finishGetList"
        ></pagination-link>
    </div>
</template>

<script>
import eventUtil from "@/components/event/eventUtil.js";
import EventFilterLink from "@/components/event/link/EventFilterLink.vue";
import EventListLink from "@/components/event/link/EventListLink.vue";
import PaginationLink from "@/components/event/link/PaginationLink.vue";
import EventEmptyLink from "@/components/event/link/EventEmptyLink.vue";
import filterMixins from "@/mixins/filterMixins";
import listMixins from "@/mixins/listMixins";
import util from "@/mixins/util";
import alertMixins from "@/mixins/alertMixins";
import EventBus from "@/utils/event-bus";

const { filterConfig } = eventUtil;

export default {
    name: "EventListComponent",
    mixins: [eventUtil, filterMixins, listMixins, alertMixins],
    components: {
        EventEmptyLink,
        PaginationLink,
        EventListLink,
        EventFilterLink,
    },
    inject: [],
    provide() {
        return {
            setPage: this.setPage,
            setFilter: this.setFilter,
            setSort: this.setSort,
            setMultiFilter: this.setMultiFilter,
        };
    },
    props: {
        eventType: {
            default: () => "",
        },
        eventKey: { default: 0 },
    },
    data() {
        return {
            listLayout: "",
            filterLayout: "",
            emptyLayout: "",
            paginationLayout: "",
            paginationType: "",
            getFunc: "",
            listApiParamSet: {},
            pageRow: 10,
            isGetFirst: true,
            eventStatus: "none_empty_text",
            sortOptions: {},

            eventCategoryList: [],
        };
    },
    beforeRouterEnter() {},
    created() {
        this.initConfig();
        this.getEventCategory();
        this.setFilterConfig(filterConfig);
        this.setParserType("url", this.$route.fullPath);
    },
    mounted() {
        if (this.isGetFirst) {
            this.getList(true);
        } else {
            this.finishGetList = true;
        }
    },
    beforeUnmount() {},
    unmounted() {},
    computed: {},
    methods: {
        initConfig() {
            let config = this.returnEventConfig(this.eventType);
            this.listLayout = config.listLayout;
            this.filterLayout = config.filterLayout;
            this.paginationLayout = config.paginationLayout;
            this.paginationType = config.paginationType;
            this.emptyLayout = config.emptyLayout;
            this.getFunc = config.getFunc;
            this.listApiParamSet = config.listApiParamSet;
            this.keyName = config.keyName;
            this.isGetFirst = config.isGetFirst ?? true;
            this.eventStatus = config.eventStatus ?? "none_empty_text";
            this.sortOptions = config.sortOptions;
            this.setSortOptions(this.sortOptions);

            let params = JSON.parse(JSON.stringify(this.listApiParamSet));

            if (config.pageRow && config.pageRow !== this.filterSet.page.size) {
                this.pageRow = config.pageRow;
                this.filterSet.page.size = config.pageRow;
                this.pageClass.setterPage("size", this.pageRow);
            }

            if (!util.isEmpty(this.keyName)) {
                params[this.keyName] = this.UserInfo.mb_no;
            }

            let listOptions = {
                url: this.$api.$event[this.getFunc],
                defaultParams: params,
            };
            this.initList("api", listOptions);
        },
        getEventCategory() {
            let params = {};
            try {
                this.$api.$event
                    .getEventCategory(params)
                    .then((res) => res.Data.Result)
                    .then((res) => {
                        if (!util.isEmpty(res.List) && res.List.length > 0) {
                            this.eventCategoryList = res.List;
                        }
                    });
            } catch (e) {
                console.log(e);
            }
        },
    },
    watch: {},
};
</script>

<style scoped>
</style>
