<template>
    <div>
        <event-detail-component :event-type="'eventDetail'" :event-key="eventKey"></event-detail-component>
        <share-modal></share-modal>
    </div>
</template>


<script>
import PageHeader from "@/components/PageHeader.vue";
import { mapState } from "vuex";
import alertMixins from "@/mixins/alertMixins";
import EventDetailComponent from "@/components/event/EventDetailComponent.vue";
import ShareModal from '@/components/modal/ShareModal.vue';

export default {
    name: "EventDetail",
    mixins: [alertMixins],
    components: {
        EventDetailComponent,
        PageHeader,
        ShareModal
    },
    props: {},
    data() {
        return {
            eventKey: this.$route.params.idx,
        };
    },
    beforeRouterEnter() {},
    created() {},
    mounted() {},
    beforeUnmount() {},
    unmounted() {},
    computed: {
        ...mapState({
            UserInfo: (state) => state.userInfo.UserInfo,
        }),
    },
    methods: {},
    watch: {},
};
</script>
