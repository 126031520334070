<template>
    <div id="app"
         v-loading.fullscreen.lock="loading"
         :element-loading-text="loadingText"
         element-loading-custom-class="dgmong_loading"
         v-if="showPage"
    >
        <page-header></page-header>
        <div class="dgm_wrap">
            <router-view/>
        </div>
        <page-footer v-if="!$route.meta.noFooter"></page-footer>
    </div>
</template>

<script>
import PageHeader from "./components/PageHeader";
import PageFooter from "./components/PageFooter";
import EventBus from "@/utils/event-bus";
import {setAuthInHeader} from "@/api/request";
import util from "@/mixins/util";
import {mapState} from "vuex";
import MobileDetect from "mobile-detect";

export default {
    name: "App",
    mixins: [],
    components: {
        PageFooter,
        PageHeader
    },
    props: {},
    provide() {
        return {
            changeLoading: this.changeLoading,
        }
    },
    data() {
        return {
            loading: false,
            loadingText: '',
            showPage: false,
        }
    },
    created() {
        //#todo 실서버 빌드스 주석 해제해주세요
        let md = new MobileDetect(window.navigator.userAgent);
        if(md.mobile() !== null){
            // location.href="http://m.minglemint.com" + this.$route.fullPath;
            location.href="http://testmobile.minglemint.com" + this.$route.fullPath;
            return false;
        }
        this.showPage = true;
        this.$store.dispatch('itemCategory/setItemCategory', {});
        this.$store.dispatch('utilityCategory/setUtilityCategory', {});
        this.$store.dispatch('competitionCategory/setCompetitionCategory', {});
        let token = this.$localStorage.get('token');
        if (!util.isEmpty(token)) {
            this.$store.dispatch('userInfo/setUserInfo', {token: token});
            this.$store.dispatch('collection/setTokenCollectionList',{data:token})
        }
    },
    mounted() {
        EventBus.$on('changeLoading', this.changeLoading)
        EventBus.$on('apiLoading', this.changeLoading)
        EventBus.$on('setProgress', this.setProgress);
        EventBus.$on('setMetaTitle', this.setMetaTitle);
        let lang = this.$localStorage.get('lang');
        if (util.isEmpty(lang)) {
            this.$localStorage.set('lang', 'ko');
            this.$i18n.locale = 'ko';
            this.$store.dispatch('country/changeCountry', {data: 'ko'});
        } else {
            this.$localStorage.set('lang', lang);
            this.$i18n.locale = lang;
            this.$store.dispatch('country/changeCountry', {data: lang});
        }
        var userAgent = window.navigator.userAgent;
        var isChrome = userAgent.indexOf('Chrome') > -1;
        var isEdge = userAgent.indexOf('Edg') > -1;
        if(!isChrome || (isChrome && isEdge)) {
            this.$message({
                message: this.$t('web_optimized_chrome'),
                customClass: "chrome_alert",
                center: true,
            });
        }
    },
    beforeDestroy() {
        EventBus.$off('changeLoading')
        EventBus.$off('apiLoading')
        EventBus.$off('setProgress');
        EventBus.$off('setMetaTitle');
    },
    destroyed() {
    },
    computed: {},
    methods: {
        changeLoading(value, text = '') {
            this.loading = value;
            if (!value) {
                this.loadingText = '';
            } else {
                this.loadingText = text;
            }
            //return this.loading;
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(true);
                }, 50)
            })
        },
        setProgress(value) {
            if (value === false) {
                this.uploading = false;
                this.progress = 0;
            } else if (value > 0) {
                this.uploading = true;
                this.progress = value;
            }
        },
        //페이지 타이틀 수정
        setMetaTitle(title, owner) {
            if (!util.isEmpty(title) && !util.isEmpty(owner)) {
                document.title = `${title}, ${owner} | 밍글민트(Minglemint NFT Marketplace)`
            } else if (!util.isEmpty(title)) {
                document.title = `${title} | 밍글민트(Minglemint NFT Marketplace)`
            } else {
                document.title = `밍글민트(Minglemint NFT Marketplace)`
            }
        }

    },
    watch: {},
}
</script>
<style lang="scss">
@import "assets/scss/index.scss";
</style>
