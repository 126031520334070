<template>
    <modal
        name="non-button-update-sort-modal"
        class="event-modal"
        :width="'100%'"
        :height="'auto'"
        transition="modal-bottom"
        v-model="show"
        :pivotY="0.5"
        @before-open="handlerBeforeOpen"
        @before-close="handlerBeforeClose"
    >
        <div class="box-flex pb20">
            <div class="event-modal-header">{{ $t('sort') }}</div>
            <button class="icon-close-event" @click="modalClose()"></button>
        </div>
        <div class="box-flex pb16">
            <button
                class="btn-category mr16"
                :class="{active : this.sortKeyName === 'updated' }"
                @click="sortChange('updated')"
            >{{ $t('b_update') }}</button>
            <button
                class="btn-category"
                :class="{active : this.sortKeyName === 'closed' }"
                @click="sortChange('closed')"
            >{{ $t('sort_event_closed') }}</button>
        </div>
        <button
            class="btn-category"
            :class="{active : this.sortKeyName === 'connect' }"
            @click="sortChange('connect')"
        >{{ $t('sort_event_lot') }}</button>
    </modal>
</template>

<script>
import EventBus from "@/utils/event-bus";

export default {
    name: "NonButtonUpdateSortModal",
    mixins: [],
    components: {},
    provide() {
        return {};
    },
    props: {},
    data() {
        return {
            show: false,
            filterData: {},
            sortKeyName: "updated",
            sortData: {},

            initSortData: {},
        };
    },
    beforeRouterEnter() {},
    created() {},
    mounted() {},
    beforeUnmount() {},
    unmounted() {},
    computed: {},
    methods: {
        handlerBeforeOpen(event) {
            this.initSortData = event.params.sortData;

            this.initSort();
        },

        initSort() {
            switch (this.initSortData.key) {
                case "e.updated_at":
                    this.sortKeyName = "updated";
                    break;
                case "e.e_start_date":
                    this.sortKeyName = "closed";
                    break;
                case "u.u_publish_total":
                    this.sortKeyName = "connect";
                    break;
                default:
                    this.sortKeyName = "updated";
                    break;
            }
        },

        handlerBeforeClose() {},
        modalClose() {
            this.$modal.hide("non-button-update-sort-modal");
        },
        sortChange(value) {
            this.sortKeyName = value;

            switch (this.sortKeyName) {
                case "updated":
                    this.sortData["obt"] = "e.updated_at";
                    this.sortData["obs"] = "desc";
                    break;
                case "closed":
                    this.sortData["obt"] = "e.e_start_date";
                    this.sortData["obs"] = "desc";
                    break;
                case "connect":
                    this.sortData["obt"] = "u.u_publish_total";
                    this.sortData["obs"] = "desc";
                    break;
                    break;
                default:
                    this.sortData["obt"] = "e.updated_at";
                    this.sortData["obs"] = "desc";
            }

            EventBus.$emit("setSortModal", this.sortData);
            this.modalClose();
        },
    },
    watch: {},
};
</script>

<style scoped>
</style>
