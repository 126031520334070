<template>
    <div class="box-empty-home ">
        <div class="title-empty-home pb0" v-html="$t('empty_home_5',{situation: $t(eventStatus)})"></div>
        <div class="title-empty-home pt5" v-if="userType == 'director'" v-html="$t('empty_home_5_director')"></div>
        <button class="button-empty-home" v-if="userType == 'director'" @click="updateAlert()">{{ $t('go_upload') }}</button>
    </div>
</template>

<script>
import memberMixins from "@/mixins/memberMixins";
import {mapState} from "vuex";
import alertMixins from "@/mixins/alertMixins";

export default {
    name: "EventEmptyHomeLayout",
    mixins: [memberMixins, alertMixins],
    components: {},
    props: {
        eventStatus: {default: ''},
    },
    data() {
        return {
            userType: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeUnmount() {
    },
    unmounted() {
    },
    computed: {
        ...mapState({
            UserInfo: (state) => state.userInfo.UserInfo,
        }),
    },
    methods: {
        updateAlert() {
            this.createConfirm({
                title: this.$t("btn_redirect_to_purchase_page_web"),
                content: this.$t("content_launch_web_create"),
                confirm: () => {
                },
                cancel: () => {
                },
            });
        },},
    watch: {},
}
</script>
