<template>

    <!-- 이전 디자인 -->
    <div>
<!--    <div class="box-utility list" v-for="(data,index) in utilityData" :key="`${index}boxUtility`"-->
<!--         :class="{ disabled : returnUseState(data) === '기한만료' ||  returnUseState(data) === '사용완료'}"-->
<!--         @click="moveDetail(data)"-->
<!--         >-->

<!--        <div class="img-utility" :style="`background-image : url('${returnImg(data.UtilityImg[0])}')`">-->
<!--            <div class="category-utility">{{ data.uc_name }}</div>-->
<!--            <div class="condition-utility">{{ returnUseState(data) }}</div>-->
<!--        </div>-->
<!--        <div class="wrap-content-utility">-->
<!--            <div class="text-utility-top">Utility</div>-->
<!--            <div class="title-utility">{{ data.u_name }}</div>-->
<!--            <div class="wrap-profile-utility">-->
<!--                <div class="img-profile-utility"-->
<!--                     :style="`background-image : url('${returnImg(data.collectionImg[0])}')`"></div>-->
<!--                <div class="text-profile-utility">{{ data.c_title }}</div>-->
<!--            </div>-->
<!--            <div class="wrap-info-utility">-->
<!--                <div class="text-cnt-utility"> 카운트카운트</div>-->
<!--                <div class="wrap-mingle-utility">-->
<!--                    <div class="icon-mingle-utility"></div>-->
<!--                    <div class="text-mingle-utility">{{ data.u_price }} Mg</div>-->
<!--                </div>-->
<!--                <div class="text-price-utility">(￦ {{ data.u_price }}0)</div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->

    <!-- 변경된 디자인 -->

        <div class="box-flex f-wrap nticket-list-gap">

            <div class="wrap-nticket-common" v-for="(data,index) in utilityData" :key="`${index}boxUtility`"
                 @click="moveDetail(data)">
                <ticket-image
                    :svgWidth="ticketWidth"

                ></ticket-image>
                <!--                :ticketLeftColor="getTicketColor('left')"-->
                <!--                :ticketRightColor="getTicketColor('right')"-->
                <div class="category-nticket-common">
                    <div class="text-category-nticket">{{
                            isKorean(data.uc_name, data.uc_name_eng)
                        }}
                    </div>
                </div>
                <div class="info-nticket-common">
                    <div class="fg-1 pl10 pr10">
                        <div class="pb4 fz17 fw500">{{ data.u_name }}</div>
                        <div class="box-flex pb12">
                            <div class="icon-mingle-utility"></div>
                            <div class="fz15 fw700 c33">{{ data.u_price }} Mg</div>

                            <div class="fz12 c80 pl3">(￦ {{ data.u_price }})</div>
                        </div>
                        <div class="box-flex f-wrap">

                            <div class="icon-nticket-share main-icon"
                                 v-if="data.Collections && data.Collections.Main.length>0"
                                 :style="`background-image : url('${returnNewImage(data.Collections.Main[0],'MainFile')}')`"></div>

                            <div class="fz11 fw500 c66 pl5 pr3">
<!--                                TODO-->
<!--                                {{ $t('other_things', {data: data.Collections.Main[0].c_title}) }}-->
                            </div>
                            <div class="box-flex">
                                <div
                                    v-if="data.Collections && data.Collections.Sub.length>0"
                                    class="fz11 fw500 c66 pr3">
                                    <!--                                TODO-->
<!--                                {{ $t('counts_collection', {count: data.Collections.Sub.length}) }}-->
                                </div>
<!--                                TODO-->
                                <div class="box-flex pl6" v-if="data.Collections">
                                    <div class="icon-nticket-share"
                                         :style="`background-image : url('${returnNewImage(subCollection, 'MainFile')}')`"
                                         v-for="(subCollection, collectionIndex) in data.Collections.Sub"
                                         :key="`${collectionIndex}popularMainUtilityDetail`"
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="image-nticket-common"
                         :style="`background-image : url('${returnImg(data.UtilityImg[0])}')`">
                        <div class="box-image-nticket">
                            <div class="icon-12-common heart"></div>
                            <!--                                TODO-->
                            <div class="pl2 fz12 fw500 c80">{{ returnCnt(data.uu_like) }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>

import util from "@/mixins/util";
import dateMixins from "@/mixins/dateMixins";
import TicketImage from "@/components/element/TicketImage.vue";
import {mapState} from "vuex";

export default {
    name: "UtilityListItemLayout",
    mixins: [dateMixins],
    components: {
        TicketImage,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {
        utilityData: {
            default: () => [],
        },

    },
    data() {
        return {
            ticketWidth: (1200 - 60) / 3,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },

    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        }),
    },
    methods: {
        returnNewImage(data, key) {
            if (!util.isEmpty(data) && !util.isEmpty(data[key][0]) && data[key][0].a_idx > 0) {
                return `${data[key][0].org_url}`;
            } else {
                return '';
            }
        },
        returnImg(data) {
            if (!util.isEmpty(data)) {
                return data.org_url;
            } else {
                return '';
            }
        },

        returnUseState(data) {
            let now = this.returnDateTime(new Date());
            let start = data.u_use_start_date;
            let end = data.u_use_end_date;

            if (data.u_is_use == 1) {
                if (start > now) {
                    return this.$t('use_await') // 사용대기
                } else if (start < now && end > now) {
                    return this.$t('use_possible'); // 사용가능
                } else if (end < now) {
                    return this.$t('use_end_already') //기한만료
                } else {
                    console.log('error')
                }
            } else {
                return this.$t('use_fin') // 사용완료
            }
        },
        moveDetail(data) {
            this.$router.push(`/utility/${data.u_idx}`)
        },
        getTicketColor(direction) {
            const colors = {
                left: '#007B94',
                right: '#fff',
                expired: '#ccc',
            };

            // 기한 만료 또는 사용 완료일 때
            if (this.returnUseState(this.data) === '기한만료' || this.returnUseState(this.data) === '사용완료') {
                return colors.expired;
            }

            // 방향에 따라 색상 반환
            return colors[direction];
        },

        isKorean(korean, english) {
            return this.UserInfo.ma_language == 1
                ? korean
                : english
                    ? english
                    : korean;
        },
        returnCnt(item) {
            if (item > 999) {
                return '999+';
            } else {
                return item;
            }
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
