const utilityConfig = {
    utility: {
        getFunc: 'getUtility',
        listLayout: 'UtilityListDefaultLayout',
        filterLayout: 'UtilityFilterDefaultLayout',
        emptyLayout: 'UtilityEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        listApiParamSet: {
            uu: 1,
            uu_is_deal: [0, 1]
        },
        getSuccessType: 1,
        getErrorTypes: {},
        keyName: 'c_idx',
        pageRow: 20,
    },
    utilityCollections: {
        getFunc: 'getCollectionsUtility',
        listLayout: 'UtilityListDefaultLayout',
        filterLayout: 'UtilityFilterDefaultLayout',
        emptyLayout: 'UtilityEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        listApiParamSet: {
        },
        getSuccessType: 1,
        getErrorTypes: {},
        keyName: 'c_idx',
        pageRow: 20,
        sortOptions: {
            keyName: 'obt',
            orderName: 'obs',
        },
    },
    utilityDetail: {
        getFunc: 'getNticket',
        detailLayout: 'UtilityDetailDefaultLayout',
        detailApiParamsSet: {
        },
        getSuccessType: 1,
        getErrorTypes: {},
        keyName: 'u_idx',
    },
    utilityUserDetail: {
        getFunc: 'getUtility',
        detailLayout: 'UtilityDetailUserLayout',
        detailApiParamsSet: {
            uu:1,
            uu_is_deal: [0, 1],
            uucnt: 1
        },
        getSuccessType: 1,
        getErrorTypes: {},
        keyName: 'uu_idx',
    }
}
export default {
    methods: {
        returnUtilityConfig(type) {
            return utilityConfig[type];
        },
    },
    filterConfig: {
        u_name: {
            type: 'query',
            classname: 'searchInput'
        },
        dir_name: {
            type: 'query',
            classname: 'searchInput'
        },
        dir_nick: {
            type: 'query',
            classname: 'searchInput'
        },
        search: {
            type: 'query',
            classname: 'searchInput'
        },
        uc_idx: {
            type: 'terms',
            classname: 'filterCategory',
        },
        usest: {
            type: 'terms',
            classname: 'filterStatus',
        },
        sellst: {
            type: 'terms',
            classname: 'filterType',
        },
        minprice: {
            type: 'match',
            classname: 'minprice'
        },
        maxprice: {
            type: 'match',
            classname: 'maxprice'
        },
    }
}

