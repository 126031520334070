import parserFactory from '@/mixins/filterFactory/parserFactory';
import Page from "@/mixins/filter/page";
import Sort from "@/mixins/filter/sort";
import Filter from "@/mixins/filter/filter"
import filterFactory from "@/mixins/filterFactory/filterFactory";

let parsingDataPage = 1;
export default {
	mixins: [  ],
	components: {
	},
	props: {

	},
	data() {
		return{
			filterSet: {
				filter: {},
				page: {
					page: 1,
					size: 10,
					total: 0,
				},
				sort: {},
			},
			parserType: '',
			pageClass: new Page(),
			sortClass: new Sort(),
			filterClass: new Filter(),
			isInitPage: false,
		}
	},
	beforeRouterEnter() {},
	created() {
	},
	mounted() {
	},
	beforeDestroy() {
	},
	destroyed() {},
	methods:{
		setParserType(type, data) { // parserType Set type은 url, vuex
			this.parserType = type;
			this.parsingSaveFilter(this.parserType, data);
		},
		setFilterConfig(data={}) { //filterConfig Set, 모든 값에 classname, type이 필수,
			this.filterClass.setterFilterConfigs(data);
		},
		setFilter(key=null, value=null) { //filterSet의 filter Set 해줌. type은 match, between, query
			let filter = this.filterClass.setterFilter(key, value);
			this.isInitPage = true;
			// this.changeSaveFilter(this.parserType);
			this.setterFilterSet(filter, 'filter')
		},
		setMultiFilter(data){
			let filter = {}
			Object.entries(data).forEach(([key,value]) => {
				filter = this.filterClass.setterFilter(key, value);
			})
			this.isInitPage = true;
			this.setterFilterSet(filter, 'filter')
		},
        resetFilter() { //필터 초기화
            let filter = this.filterClass.resetFilter();
            this.isInitPage = true;
            this.setterFilterSet(filter, 'filter');
        },
		setPage(key, value) { //filterSet의 page Set 함수. value = Number, key = page, size, total
			let page = this.pageClass.setterPage(key, value);
			// this.changeSaveFilter(this.parserType);
			this.setterFilterSet(page, 'page')
		},
        setSortOptions(options, defaultSort=null) {
		    this.sortClass.setSortOptions(options);
		    if(defaultSort) {
                let sort = this.sortClass.setterSort(defaultSort.key, defaultSort.order);
                this.filterSet['sort'] = sort;
            }
        },
		setSortKeys(data) {
			let sort = this.sortClass.setterSortKeys(data);
			// this.changeSaveFilter(this.parserType);
			this.setterFilterSet(sort, 'sort')
		},
		setSort(key=null, order=null) {
			let sort = this.sortClass.setterSort(key, order);
            this.isInitPage = true;
			// this.changeSaveFilter(this.parserType);
			this.setterFilterSet(sort, 'sort')
		},
		setterFilterSet(data=null, key=null) {
			if(!data) {
				return false;
			}
			if(!key) {
				this.filterSet = data;
			}else{
				this.filterSet[key] = data;
			}
			this.changeSaveFilter(this.parserType);
		},
		getFilterSet() {
			return this.filterSet;
		},
		changeSaveFilter(type) {
			let parserfactory = parserFactory.parserCreate(type);
			let saveFilter = parserfactory.getMakeSaveFilter(this.getFilterSet());
			switch (type) {
				case 'url':
				    let beforeQuery = JSON.parse(JSON.stringify(this.$route.query));
					let url = `${this.$route.path}${saveFilter}`;
					if(decodeURIComponent(this.$route.fullPath) != url) {
						this.$router.replace(url)
                        if(beforeQuery && Object.keys(beforeQuery).length > 0) {
                            this.getList();
                        }
					}
					break;
				case 'vuex':
				    let vuexFilterSet = this.$store.getters['common/getFilterSet'];
				    if(JSON.stringify(vuexFilterSet) != JSON.stringify(saveFilter)) {
                        this.$store.dispatch('common/setFilterSet', {data: saveFilter});
                        this.getList();

                    }
					break;
			}
		},
		parsingSaveFilter(type, data) {
			let parserfactory = parserFactory.parserCreate(type);
			parserfactory.parsingFilter(data);
			let parsingFilterSet = parserfactory.getterFilterSet();
			this.setterParsingFilterSetData(parsingFilterSet);
		},
		setterParsingFilterSetData(parsingFilterSet) {
			this.filterSet.filter = this.filterClass.initFilter(parsingFilterSet.filter);
			let filterKeys = Object.keys(parsingFilterSet.filter);
			let filterConfigs = this.filterClass.getterFilterConfigs();
			filterKeys.forEach(filterKey => {
			    if(filterConfigs.hasOwnProperty(filterKey)) {
                    let key = filterKey;
                    let value = parsingFilterSet.filter[filterKey];
                    let classname = filterConfigs[key].classname;
                    let type = filterConfigs[key].type;
                    if(type === 'between' || type === 'terms' || type === 'distance') {
                        let filterfactory = filterFactory.filterCreate(type);
                        value = filterfactory.parserReturnValue(value);
                    }
                    this.setParserDateInData(key, value, classname);
                }
			});
			this.filterSet.sort = this.sortClass.setterSort(parsingFilterSet.sort.key, parsingFilterSet.sort.order);
			this.filterSet.page = this.pageClass.setterPage('size', parsingFilterSet.page.size);
			this.filterSet.page = this.pageClass.setterPage('page', parsingFilterSet.page.page);
			this.setPasingDataPage(parsingFilterSet.page.page);
			// this.changeSaveFilter(this.parserType);
		},
		setParserDateInData(key, value, classname) {
			if(this.hasOwnProperty(classname)) {
				this[classname] = value;
			}
			if(this.hasOwnProperty(`${classname}Option`)) {
				this[`${classname}Option`] = key;
			}
		},
		setParsingDataPageFilterSet() {
			// this.setPage(parsingDataPage, 'page');
			this.filterSet.page.page = parsingDataPage;
		},
		setPasingDataPage(page) {
			parsingDataPage = Number(page) > 0 ? Number(page) : 1;
		},
		getPasingDataPage() {
			return parsingDataPage;
		},
		getList() { //에러방지

		},
	},
	computed: {
	},
	watch: {
	},
}
