<template>
    <div class="item_status_component">
        <div class="bid_wrap license_wrap vote_detail item_status_wrap">
            <div class="bid_main_wrap" v-if="showLayout">
                <div class="bid_content_wrap">
                    <div class="bid_title_wrap">
                        <h1 class="title">{{ $t('i_status') }}</h1>
                        <div class="validation pt10" v-if="isAuctioning">{{
                                $t('item_status_update_auction_err')
                            }}
                        </div>
                    </div>

                    <item-info-view-layout
                            :setValidatorArr="setValidatorArr"
                            :setComponentData="setComponentData"
                            :setCategoryData="setCategoryData"
                            :itemData="itemData"
                            :itemClass="itemClass"></item-info-view-layout>
                    <div class="status_content_wrap">
                        <div class="status_content_box">
                            <h2>{{ $t('info_item') }}</h2>
                            <ul class="i_write_content_wrap">
                                <item-extra-info-layout
                                        ref="ItemExtraInfoLayout"
                                        :setValidatorArr="setValidatorArr"
                                        :setComponentData="setComponentData"
                                        :setDeleteFiles="setDeleteFiles"
                                        :itemData="itemData"
                                        :itemClass="itemClass"></item-extra-info-layout>
                                <item-hashtag-layout
                                        ref="ItemHashtagLayout"
                                        :setValidatorArr="setValidatorArr"
                                        :setComponentData="setComponentData"
                                        :childCategory="childCategory"
                                        :itemData="itemData"
                                        :itemClass="itemClass"></item-hashtag-layout>
                                <item-information-layout
                                        ref="ItemInformationLayout"
                                        :setValidatorArr="setValidatorArr"
                                        :setComponentData="setComponentData"
                                        :itemData="itemData"
                                        :itemClass="itemClass"></item-information-layout>
                            </ul>

                            <h2 class="sell_status">{{ $t('sell_status') }}</h2>
                            <ul class="i_write_content_wrap">
                                <div class="w_content_box mb0">
                                    <el-radio-group class="el_radio_box" v-model="status"
                                                    @change="changeStatus" :disabled="isAuctioning">
                                        <el-radio class="el_radio_btn" :label="1" v-if="getMemberType() === 'normal'  && category != 178" :disabled="itemData.collectOwner === 0">
                                            {{ $t('auction') }}
                                        </el-radio>
                                        <el-radio class="el_radio_btn" :label="2" v-if="getMemberType() === 'curator'  && category != 178" :disabled="itemData.collectOwner === 0">
                                            {{ $t('auction') }}
                                        </el-radio>
                                        <el-radio class="el_radio_btn" :label="3"
                                                  v-if="type !== 'real' && getMemberType() === 'normal'" :disabled="itemData.collectOwner === 0">
                                            {{ $t('licenseSell') }}
                                        </el-radio>
                                        <el-radio class="el_radio_btn" :label="4" v-if="getMemberType() === 'normal'  && category != 178" :disabled="itemData.collectOwner === 0">
                                            {{ $t('fix_selling') }}
                                        </el-radio>
                                        <el-radio class="el_radio_btn" :label="5" v-if="getMemberType() === 'curator'  && category != 178" :disabled="itemData.collectOwner === 0">
                                            {{ $t('fix_selling') }}
                                        </el-radio>
                                        <el-radio class="el_radio_btn" :label="9">
                                            {{ $t('no_sell') }}
                                        </el-radio>
                                    </el-radio-group>
                                </div>
                                <div class="mg_text" v-html="$t('mg_info_text')"></div>
                                <div v-if="showStatusLayout">
                                    <item-auction-layout
                                            ref="ItemAuctionLayout"
                                            v-if="status === 1 || status === 2"
                                            :setValidatorArr="setValidatorArr"
                                            :setComponentData="setComponentData"
                                            :itemData="itemData"
                                            :itemClass="itemClass"
                                    ></item-auction-layout>
                                    <item-license-layout
                                            ref="ItemLicenseLayout"
                                            v-if="status === 3"
                                            :setValidatorArr="setValidatorArr"
                                            :setComponentData="setComponentData"
                                            :itemData="itemData"
                                            :itemClass="itemClass"
                                    ></item-license-layout>
                                    <item-limits-sale-layout
                                            ref="ItemLimitsLayout"
                                            v-if="status === 4 || status === 5"
                                            :setValidatorArr="setValidatorArr"
                                            :setComponentData="setComponentData"
                                            :itemData="itemData"
                                            :itemClass="itemClass"
                                    ></item-limits-sale-layout>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!itemData.isAuctioning">
                <item-auction-save-layout
                        v-if="status === 1 || status === 2"
                        :setValidatorArr="setValidatorArr"
                        :setComponentData="setComponentData"
                        :checkValidator="checkValidator"
                        :itemData="itemData"
                        :itemClass="itemClass"></item-auction-save-layout>
                <item-license-save-layout
                        v-if="status === 3"
                        :setValidatorArr="setValidatorArr"
                        :setComponentData="setComponentData"
                        :checkValidator="checkValidator"
                        :itemData="itemData"
                        :itemClass="itemClass"></item-license-save-layout>
                <item-limits-save-layout
                        v-if="status === 4 || status === 5"
                        :setValidatorArr="setValidatorArr"
                        :setComponentData="setComponentData"
                        :checkValidator="checkValidator"
                        :itemData="itemData"
                        :itemClass="itemClass"></item-limits-save-layout>
                <item-no-sale-save-layout
                        v-if="status === 9"
                        :setValidatorArr="setValidatorArr"
                        :setComponentData="setComponentData"
                        :checkValidator="checkValidator"
                        :itemData="itemData"
                        :itemClass="itemClass"></item-no-sale-save-layout>

            </div>

            <div class="caption_check">
                <div class="mt10" v-if="isValidationFail"><p>{{ $t('caption_status_terms') }}</p></div>
            </div>
        </div>
    </div>
</template>

<script>
import ItemInfoViewLayout from "@/components/item/layout/itemCreate/ItemInfoViewLayout";
import ItemExtraInfoLayout from "@/components/item/layout/itemCreate/ItemExtraInfoLayout";
import ItemHashtagLayout from "@/components/item/layout/itemCreate/ItemHashtagLayout";
import ItemInformationLayout from "@/components/item/layout/itemCreate/ItemInformationLayout";
import ItemAuctionLayout from "@/components/item/layout/itemCreate/ItemAuctionLayout";
import ItemLicenseLayout from "@/components/item/layout/itemCreate/ItemLicenseLayout";
import ItemLimitsSaleLayout from "@/components/item/layout/itemCreate/ItemLimitsSaleLayout";
import ItemAuctionSaveLayout from "@/components/item/layout/itemCreate/ItemAuctionSaveLayout";
import ItemLicenseSaveLayout from "@/components/item/layout/itemCreate/ItemLicenseSaveLayout";
import ItemLimitsSaveLayout from "@/components/item/layout/itemCreate/ItemLimitsSaveLayout";
import ItemNoSaleSaveLayout from "@/components/item/layout/itemCreate/ItemNoSaleSaveLayout";


import Item from "@/components/item/Item"
import AuthMixins from "@/mixins/AuthMixins";
import itemValidator from "@/components/item/itemValidator";
import awsS3FileUpload from "@/mixins/awsS3FileUpload";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import alertMixins from "@/mixins/alertMixins";

export default {
    name: "ItemStatusComponent",
    mixins: [AuthMixins, itemValidator, awsS3FileUpload, alertMixins],
    components: {
        ItemInfoViewLayout,
        ItemExtraInfoLayout,
        ItemHashtagLayout,
        ItemInformationLayout,
        ItemAuctionLayout,
        ItemLicenseLayout,
        ItemLimitsSaleLayout,
        ItemAuctionSaveLayout,
        ItemLicenseSaveLayout,
        ItemLimitsSaveLayout,
        ItemNoSaleSaveLayout,
    },
    props: {
        itemKey: {default: '',}
    },
    data() {
        return {
            itemClass: null,
            itemType: '',

            validatorArr: [],
            childCategory: [],
            deleteFiles: [],
            isValidationFail: true,
            isRunning: false,

            fileInfo: [
                {fileName: 'detailFile', fileType: 'item_detail',},
            ],

            getKeyName: 'sku',
            showLayout: false,
            itemData: {},
            i_idx: '',
            itemMarketType: 9,

            isAuctioning: false,
            showStatusLayout: false,
        }
    },
    created() {
        this.itemClass = new Item.ItemClass(this.UserInfo)
        if (!util.isEmpty(this.itemKey)) {
            this.getItem(this.itemKey);
        } else {
            this.createAlert({
                content: this.$t('no_data_loading'),
                hide: () => {
                    this.$router.back();
                }
            })
        }
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        setValidatorArr(arr) {
            this.validatorArr = this.$_.concat(this.validatorArr, arr)
            this.validatorArr = this.$_.uniq(this.validatorArr)
        },
        setComponentData(type, value) {
            this[type] = value;
            if (this.isValidationFail) {
                this.isValidationFail = false;
            }
        },
        setDeleteFiles(files) {
            //삭제하는 파일 a_idx 로 key 만 가지고 있기.
            util.Array.each(files, (file, index, arr) => {
                if (!util.isEmpty(file.a_idx)) {
                    this.deleteFiles.push(file.a_idx);
                }
            })
            this.deleteFiles = this.$_.uniq(this.deleteFiles)
        },
        setCategoryData(category) {
            //카테고리 Object 로 주면 child 를 this.childCategory 에 담아서 prop 으로 레이아웃에 넘겨주기
            if (!util.isEmpty(category.child)) {
                this.childCategory = category.child.slice();
            } else {
                this.childCategory = [];
            }
        },
        loadLayout() {
            this.showLayout = true;
            this.showStatusLayout = true;
            this.$nextTick(() => {
                $(document).scrollTop(0);
            })
        },
        getItem(key) {
            let params = {}
            params[this.getKeyName] = key;
            try {
                this.$api.$item.getItemDetail(params).then(res => res.Data.Result).then(res => {
                    if (res.Info.type !== 1 || util.isEmpty(res.List)) {
                        this.createAlert({
                            content: this.$t('no_data_loading'),
                            hide: () => {
                                this.$router.back();
                            }
                        })
                        return false;
                    }
                    this.i_idx = res.List[0].i_idx;
                    this.itemData = this.itemClass.getItemStatusData(res.List[0])
                    if (this.itemData.hasOwnProperty('updateMember') && this.itemData.updateMember != this.UserInfo.mb_no) {
                        this.createAlert({
                            content: this.$t('no_data_loading'),
                            hide: () => {
                                this.$router.back();
                            }
                        })
                        return false;
                    }
                    util.Object.each(this.itemData, (key, value, obj) => {
                        this[key] = this.$_.cloneDeep(value);
                    })
                    this.loadLayout();
                })
            } catch (e) {
                console.log(e);
            }
        },
        changeStatus() {
            this.showStatusLayout = false;
            this.validatorArr = [];
            this.initStatusDate();
            this.$nextTick(() => {
                this.showStatusLayout = true;
            })
        },
        initStatusDate() {
            //경매 값 초기화
            this.itemData.minimumSetting = 0;
            this.itemData.minimum = '';
            this.itemData.immediatelySetting = 0;
            this.itemData.immediate = '';
            this.itemData.startDateStatus = 0;
            this.itemData.startDate = '';
            this.itemData.endDate = '';
            this.minimumSetting = 0;
            this.minimum = '';
            this.immediatelySetting = 0;
            this.immediate = '';
            this.startStatus = 0;
            this.startDate = '';
            this.endDate = '';
            //라이센스 값 초기화
            this.itemData.smallType = false;
            this.itemData.smallPrice = '';
            this.itemData.mediumType = false;
            this.itemData.mediumPrice = '';
            this.itemData.largeType = false;
            this.itemData.largePrice = '';
            this.itemData.caution = '';
            this.smallType = false;
            this.smallPrice = '';
            this.mediumType = false;
            this.mediumPrice = '';
            this.largeType = false;
            this.largePrice = '';
            this.caution = '';

            this.itemData.originalPrice = '';
            this.originalPrice = '';
        },
        finishRun() {
            this.isRunning = false;
            EventBus.$emit('apiLoading', false);
        },
        checkValidator() {
            if (this.isRunning) {
                return false;
            }
            EventBus.$emit('apiLoading', true);
            this.isRunning = true;
            this.$validate(this.validatorArr).then(success => {
                if (!success) {
                    this.$refs.ItemExtraInfoLayout.checkValidator();
                    this.$refs.ItemHashtagLayout.checkValidator();
                    this.$refs.ItemInformationLayout.checkValidator();
                    if (this.$refs.hasOwnProperty('ItemAuctionLayout') && !util.isEmpty(this.$refs.ItemAuctionLayout)) {
                        this.$refs.ItemAuctionLayout.checkValidator();
                    }
                    if (this.$refs.hasOwnProperty('ItemLicenseLayout') && !util.isEmpty(this.$refs.ItemLicenseLayout)) {
                        this.$refs.ItemLicenseLayout.checkValidator();
                    }
                    if (this.$refs.hasOwnProperty('ItemLimitsLayout') && !util.isEmpty(this.$refs.ItemLimitsLayout)) {
                        this.$refs.ItemLimitsLayout.checkValidator();
                    }
                    this.isValidationFail = true;
                    this.finishRun();
                    this.$forceUpdate()
                } else {
                    this.run();
                }
            })
        },
        makeSendParams() {
            let params = {
                i_type: this.type,
                ic_idx: this.category,
                im_adult_chk: this.isCheckAdult,
                hashtag: this.hashtag,
                i_memo: this.information,
                im_status: this.status,
                im_edition: this.isEdition,
                type:'mint'
            }
            if (!util.isEmpty(this.i_idx)) {
                params.i_idx = this.i_idx;
            }
            if (this.itemType === 'auction') {
                params.ia_is_minimum = this.minimumSetting;
                params.ia_minimum = this.minimum;
                params.ia_is_immediately = this.immediatelySetting;
                params.ia_immediately = this.immediately;
                params.ia_start_date = this.startDate;
                params.ia_end_date = this.endDate;
                if (this.startDateStatus === 0) {
                    params.ia_start_date = this.startDate = this.$moment().format('YYYY-MM-DD HH:mm');
                }
            } else if (this.itemType === 'license') {
                params.il_small_price = this.smallPrice
                params.il_medium_price = this.mediumPrice
                params.il_large_price = this.largePrice
                params.il_original_price = this.originalPrice
                params.il_caution = this.caution
                params.im_type = this.exifType;
            } else if (this.itemType === 'limits') {
                params.i_price = this.originalPrice;
            } else if (this.itemType === 'nosale') {
                params.i_price_org = this.itemData.priceOrg;
            }
            return params;
        },
        async run() {
            let data = this.makeSendParams();
            let result = await this.itemClass.runWrite(this.itemType, data);
            if (result.type === true) {
                let deleteResult = await this.runDeleteFile();
                this.setMultiFileUpload(result.key);
            } else {
                this.finishRun();
                this.errorAlert(`${this.$t('createItemMsg3')}`);
            }
            this.finishRun();
        },
        runDeleteFile() {
            return new Promise(resolve => {
                this.runS3ApiDelete(this.deleteFiles, 0, resolve);
            })
        },
        setMultiFileUpload(key) {
            this.runUploadFileInfo(this.fileInfo, 0, key).then(res => {
                this.openAlert();
            });
        },
        openAlert() {
            this.createAlert({
                title: '',
                content: this.$t('updateItemMsg1'),
                btnText: this.$t('yes'),
                hide: () => {
                    let moveUrl = this.getMoveUrl();
                    this.$router.push(moveUrl);

                }
            });
        },
        getMoveUrl() {
            let moveUrl = '/openmarket'
            if (this.getMemberType() === 'curator') {
                if (this.itemType === 'nosale') {
                    moveUrl = '/mypage/curation'
                }
                moveUrl = '/premiummarket'
            } else {
                if (this.itemType === 'nosale') {
                    moveUrl = '/mypage/item?tab=myItemHold'
                }
            }
            return moveUrl;
        },
    },
    watch: {},

}
</script>

<style scoped>

</style>
