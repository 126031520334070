<template>
    <div class="container-filter">

        <div class="input-filter-common padding-common">
            <el-input class="text-input-filter" :placeholder="$t('search_placeholder')" v-model="searchInput"
                      @input="setFilterFunc('ena', searchInput)" @keypress.enter.native="setFilterFunc('ena', searchInput)">
                <template #suffix>
                    <button class="btn-input-filter" v-if="searchInput.length === 0"></button>
                    <button class="btn-input-filter close" v-else @click="reset"></button>
                </template>
            </el-input>
        </div>
        <div class="btn-category-common pt0 pb15">
            <div class="wrap-btn-category">

                <div class="gradiant-btn-category"></div>
                <div class="box-btn-category pr0">
                    <button class="btn-category" :class="{'active' : filterCategory == item.ec_idx}"
                            v-for="(item, index) in eventCategoryList" :key="`categoryFilter${index}`"
                            @click="setFilterCategory(item.ec_idx)">{{ item.ec_name }}
                    </button>
                </div>

            </div>
            <button type="button" class="btn-filter" @click="showFilter()"></button>
            <!--            <button class="wrap-sort" @click="$modal.show('non-button-sort-modal')">
                            <div class="text-sort">{{ $t('sort_updated') }}</div>
                            <div class="img-sort"></div>
                        </button>-->
        </div>
    </div>
</template>


<script>

import eventUtil from "@/components/event/eventUtil";
import EventBus from "@/utils/event-bus";
import util from "@/mixins/util";

const {filterConfig} = eventUtil;
export default {
    name: "EventFilterDefaultLayout",
    mixins: [],
    inject: ['setFilter'],
    components: {},
    props: {
        filterData: {
            default: () => {
                return {}
            }
        },
        eventCategoryList: {
            default: () => []
        },
    },
    data() {
        return {
            searchInput: '',
            filterCategory: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        EventBus.$on('setEventFilterModal', this.setEventFilterModal);
    },
    beforeUnmount() {
        EventBus.$off('setEventFilterModal');
    },
    unmounted() {
    },
    computed: {},
    methods: {
        reset() {
            this.searchInput = '';
            this.setFilterFunc('ena', this.searchInput)
        },
        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },
        setFilterCategory(key) {
            if (this.filterCategory != key) {
                this.filterCategory = key;
                this.setFilterFunc('eca', this.filterCategory);
            } else {
                this.filterCategory = '';
                this.setFilterFunc('eca', this.filterCategory);
            }
        },
        showFilter() {
            this.$modal.show('event-normal-filter-modal');
        },
        setEventFilterModal(key) {
            if (!util.isEmpty(key.filterStartPrice)) {
                this.setFilterFunc('stpr', key.filterStartPrice);
            }
            if (!util.isEmpty(key.filterEndPrice)) {
                this.setFilterFunc('enpr', key.filterEndPrice);
            }
            if (!util.isEmpty(key.usest)) {
                this.setFilterFunc('eperArr', key.usest);
            }
            if (!util.isEmpty(key.sellst)) {
                this.setFilterFunc('nperArr', key.sellst);
            }
        },
    },
    watch: {},
}
</script>
