<template>
    <item-list-component :item-type="'collectItem'" :member-key="collectKey"></item-list-component>

</template>


<script>

import ItemListComponent from "@/components/item/ItemListComponent.vue";
export default {
    name: "UtilityDetailDmember",
    mixins: [],
    components: {ItemListComponent, },
    props: {

    },
    data() {
        return {
            collectKey: this.$route.params.idx,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {

    },
    methods: {
    },
    watch: {
    },
}
</script>
