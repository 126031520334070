<template>
    <div class="creator_detail_wrap">
        <collect-detail-component :collect-type="'collectDetail'" :collect-key="collectKey"></collect-detail-component>
        <div class="wrap-tab-common">
            <div class="tab-common" :class="{'active' : tab === 'dmember'}" @click="switchTab('dmember')">
                {{ $t('dmember') }}
            </div>
            <div class="tab-common" :class="{'active' : tab === 'nticket'}" @click="switchTab('nticket')">
                {{ $t('utility') }}
            </div>
        </div>
        <router-view></router-view>
        <share-modal></share-modal>
    </div>
</template>

<script>


import CollectDetailComponent from "@/components/collect/CollectDetailComponent";
import ItemListComponent from "@/components/item/ItemListComponent";
import ShareModal from "@/components/modal/ShareModal";
import UtilityListComponent from "@/components/utility/UtilityListComponent";

export default {
    name: "CollectDetail",
    mixins: [],
    components: {UtilityListComponent, ShareModal, ItemListComponent, CollectDetailComponent},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            collectKey: this.$route.params.idx,
            tab: 'dmember',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        if (this.$route.path.split('/').pop() === 'nticket') {
            this.tab = 'nticket'
        }
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {

        switchTab(value) {
            if (this.tab === value) {
                return false;
            }
            this.tab = value;
            if (this.tab === 'dmember') {
                this.$router.replace(`/collect/${this.collectKey}`)
                return false;
            } else {
                this.$router.replace(`/collect/${this.collectKey}/${this.tab}`)
            }
        }

    },
    watch: {},
}
</script>

<style scoped>

</style>

