<template>
    <component :is="loader" v-if="loader" :eventStatus="eventStatus"></component>
</template>

<script>
import {defineAsyncComponent} from "vue";

export default {
    name: "EventEmptyLink",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        emptyLayout: {default: () => ''},
        utilityKey: {default: 0},
        eventStatus: {default: ''},
    },
    data() {
        return {
            component: null,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeUnmount() {
    },
    unmounted() {
    },
    computed: {
        loader() {
            if (!this.emptyLayout) {
                return defineAsyncComponent(() => import('@/components/event/layout/eventList/empty/EventEmptyDefaultLayout.vue'));
            }
            return defineAsyncComponent(() => import(`@/components/event/layout/eventList/empty/${this.emptyLayout}.vue`))
        },
    },
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>
