<template>
    <div class="container-filter pb16">
        <div class="input-filter-common row-padding-common mb16 mt16">
            <el-input class="text-input-filter" :placeholder="$t('search_placeholder')" v-model="searchInput"
                      @keypress.enter.native="setFilterFunc('ena', searchInput)"
                      @input="setFilterFunc('ena', searchInput)">
                <template #suffix>
                    <button class="btn-input-filter" v-if="searchInput.length === 0"></button>
                    <button class="btn-input-filter close" v-else @click="reset"></button>
                </template>
            </el-input>
        </div>
        <div class="wrap-filter-event row-padding-common pb4">
            <el-checkbox class="checkbox-common circle-checkbox" @change="setOngoingFilter()" :valu="isOngoing">
                {{ $t('view_ongoing_events_only') }}
            </el-checkbox>
            <button class="wrap-sort" @click="$modal.show('event-director-sort-modal')">
                <div class="text-sort">{{ $t(sortName) }}</div>
                <div class="img-sort"></div>
            </button>
        </div>
        <div class="btn-category-common">
            <div class="wrap-btn-category">
                <div class="gradiant-btn-category"></div>
                <div class="box-btn-category">
                    <button class="btn-category" :class="{'active' : filterCategory.includes(item.ec_idx)}"
                            v-for="(item, index) in eventCategoryList" :key="`categoryFilter${index}`"
                            @click="setFilterCategory(item.ec_idx)">{{ item.ec_name }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

import eventUtil from "@/components/event/eventUtil";
import EventBus from "@/utils/event-bus";

const {filterConfig} = eventUtil;
export default {
    name: "EventFilterDirectorLayout",
    mixins: [],
    inject: ['setFilter', 'setSort'],
    components: {},
    props: {
        filterData: {
            default: () => {
                return {}
            }
        },
        eventCategoryList: [],
    },
    data() {
        return {
            searchInput: '',
            isOngoing: false,
            activeEvent: '',
            sortName: 'sort_latest_registration',
            filterCategory: [],
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        EventBus.$on('setEventFilterModal', this.setEventFilterModal);
        EventBus.$on('setEventSort', this.setEventSort);
    },
    beforeUnmount() {
        EventBus.$off('setEventFilterModal');
        EventBus.$off('setEventSort');
    },
    unmounted() {
    },
    computed: {},
    methods: {
        reset() {
            this.searchInput = '';
            this.setFilterFunc('ena', this.searchInput)
        },
        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },
        setFilterCategory(key) {
            if (!this.filterCategory.includes(key)) {
                this.filterCategory.push(key);
            } else {
                this.filterCategory = this.filterCategory.filter((el) => el !== key);
            }
            console.log(this.filterCategory)
            this.setFilterFunc('eca', this.filterCategory);
        },
        showFilter() {
            this.$modal.show('event-filter-modal');
        },
        setEventFilterModal(key) {
            this.setFilterFunc('eperArr', key.usest);
            this.setFilterFunc('nperArr', key.nperArr);
        },
        setOngoingFilter() {
            this.isOngoing = !this.isOngoing;

            if (this.isOngoing) {
                this.activeEvent = 2;
            } else {
                this.activeEvent = '';
            }

            this.setFilterFunc('eper', this.activeEvent);
        },
        setEventSort(data) {
            this.setSort(data.obt, data.order)

            if (data.obt === 'e.created_at') {
                this.sortName = 'sort_latest_registration';
            } else {
                this.sortName = 'sort_connect_nticket';
            }
        },
    },
    watch: {},
}
</script>
