<template>
    <component :is="loader" v-if="loader" :eventData="eventData"></component>
</template>

<script>
import {defineAsyncComponent} from "vue";

export default {
    name: "EventListLink",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        listLayout: {
            default : () => '',
        },
        eventData:{
            default : () => []
        },
    },
    data() {
        return {
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeUnmount() {
    },
    unmounted() {
    },
    computed: {
        loader() {
            if (!this.listLayout) {
                return defineAsyncComponent(() => import('@/components/event/layout/eventList/list/EventListDefaultLayout.vue'));
            }
            return defineAsyncComponent(() => import(`@/components/event/layout/eventList/list/${this.listLayout}.vue`))
        },
    },
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>
  