import imageOption from "@/mixins/imageOption";
import util from "@/mixins/util";
import {request} from "@/api/request";

export default {
    mixins: [imageOption],
    methods: {
        returnMemberProfile(profile = [], imageOption = {w: 50, h: 50}) {
            let image = '';
            if (!util.isEmpty(profile)) {
                let w = util.isEmpty(imageOption.w) ? 100 : imageOption.w;
                let h = util.isEmpty(imageOption.h) ? 100 : imageOption.h;
                image = `${profile.org_url}?${this.setImageOptions(w, h)}`;
            } else {
                image = require('@/assets/image/default/imgNonProfile.png');
            }
            return image;
        },
        returnUserType(type) {
            switch (type) {
                case 0:
                    return 'normal';
                case 1:
                    return 'normal';
                case 2:
                    return 'director';
                case 3:
                    return 'manager';
                default:
                    return 'normal';
            }
        },
    },
}
