<template>
    <div class="container-event-detail">
        <div class="box-flex a-start pb88">
            <div class="thumbnail" :style="`background-image : url(${returnImg(eventData.Thumb)})`"></div>
            <div class="fg-1">
                <div class="wrap-etc-detail">
                    <div class="title-event-detail pa0 fz28">{{ eventData.e_name }}</div>
                    <div class="box-btn-etc">
                        <button class="btn-share" @click="openShareModal()"></button>
                        <button
                            class="btn-heart"
                            :class="{'active' : this.eventData.isLike}"
                            @click="toggleEventLike(eventData)"
                        ></button>
                        <div class="text-count-heart">{{ eventData.e_like }}</div>
                    </div>
                </div>
                <div class="wrap-text-info">
                    <div class="box-text detail pb16">
                        <div class="text-left info">{{ $t('category') }}</div>
                        <div
                            class="text-right info"
                        >{{ eventData.ec_name ? eventData.ec_name : "-" }}</div>
                    </div>
                    <div class="box-text detail pb16" v-if="eventData.e_time">
                        <div class="text-left info">{{ $t('duration') }}</div>
                        <div class="text-right info">{{ eventData.e_time }}</div>
                    </div>
                    <div
                        class="box-text detail mb0 pb16"
                        v-if="eventData.e_start_date && eventData.e_end_date"
                    >
                        <div class="text-left info">{{ $t('age_lock') }}</div>
                        <div class="text-right info">{{ returnGrade() }}</div>
                    </div>
                    <div class="box-text detail pb16" v-if="eventData.e_address_name">
                        <div class="text-left info">{{ $t('place') }}</div>
                        <div class="text-right info">{{ eventData.e_address_name }}</div>
                    </div>
                    <div
                        class="box-text detail mb0 pb16"
                        v-if="eventData.e_start_date && eventData.e_end_date"
                    >
                        <div class="text-left info">{{ $t('event_date') }}</div>
                        <div class="text-right info">
                            {{
                            returnDateFormat(eventData.e_start_date, "YYYY.MM.DD")
                            }}~{{ returnDateFormat(eventData.e_end_date, "YYYY.MM.DD") }}
                        </div>
                    </div>
                    <div
                        class="box-text detail mb0 pb16"
                        v-if="eventData.e_start_date && eventData.e_end_date"
                    >
                        <div class="text-left info">{{ $t('state') }}</div>
                        <div
                            class="text-right info"
                        >{{ $t(returnTicketStatus(eventData.nTicketStatus)) }}</div>
                    </div>
                    <div
                        class="box-text detail mb0 pb16"
                        v-if="eventData.e_start_date && eventData.e_end_date"
                    >
                        <div class="text-left info">{{ $t('chain_nticket') }}</div>
                        <div class="text-right info">{{ eventData.u_publish_total }}</div>
                    </div>
                </div>
                <div class="fz14 c80">{{ $t('check_app') }}</div>
            </div>
        </div>
        <div class="container-content-min">
            <div class="wrap-tab-content">
                <div class="wrap-text-detail notice">
                    <div class="text-title notice">{{ $t('noti') }}</div>
                    <div class="text-content">{{ eventData.e_info }}</div>
                </div>
                <div class="wrap-text-detail pt24">
                    <div class="text-title">{{ $t('schedule') }}</div>
                    <div class="box-text detail mb8">
                        <div class="text-left detail">{{ $t('available_booking_time') }}</div>
                        <div class="text-right detail">{{ eventData.e_reservation }}</div>
                    </div>
                    <div class="box-text detail">
                        <div class="text-left detail">{{ $t('viewing_date_time') }}</div>
                        <div>
                            <div
                                class="text-right detail pb4"
                            >{{ returnDateFormat(eventData.e_start_date, "YYYY.MM.DD HH:mm") }}</div>
                            <div
                                class="text-right detail"
                            >- {{ returnDateFormat(eventData.e_end_date, "YYYY.MM.DD HH:mm") }}</div>
                        </div>
                    </div>
                </div>
                <div class="wrap-text-detail">
                    <div class="text-title">{{ $t('performance_details') }}</div>
                    <div
                        class="img-event detail"
                        :style="`background-image : url(${eventData.Detail[0].org_url})`"
                    ></div>
                </div>
                <div class="wrap-text-detail" v-if="eventData.e_address">
                    <div class="text-title">{{ $t('place') }}</div>
                    <div class="img-event place">
                        <gmap-map
                            :options="option"
                            :zoom="17"
                            :center="this.centerPosition"
                            style="width: 100%; height: 100%;"
                        >
                            <gmap-marker
                                :key="index"
                                :position="centerPosition"
                            ></gmap-marker>
                        </gmap-map>
                    </div>
                    <div class="box-text-address">
                        <div class="text-address title">{{ eventData.e_address }}</div>
                        <div class="text-address">{{ eventData.e_address_detail }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="button-shortcut-event"
            @click="$router.push('/event')"
        >{{ $t('show_more_event') }}</div>
    </div>
</template>


<script>
import util from "@/mixins/util";
import dateMixins from "@/mixins/dateMixins";
import UtilityListComponent from "@/components/utility/UtilityListComponent.vue";

export default {
    name: "EventDetailLayout",
    mixins: [dateMixins],
    components: {
        UtilityListComponent,
    },
    inject: ["toggleEventLike"],
    props: {
        eventData: {
            default: () => {},
        },
    },
    data() {
        return {
            centerPosition:{},
            eventKey: Number(this.$route.params.idx),
            userType: "",
            replyValue: 2,
            commentInput: "",
            locPlaces: [],
            existingPlace: null,
            option: {
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: true,
                fullscreenControl: false,
                disableDefaultUi: false,
                draggable : false,
            },
        };
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {
        if (!util.isEmpty(this.eventData)) {
            this.centerPosition = {
                lat: Number(this.eventData.e_lat),
                lng: Number(this.eventData.e_long),
            };
        }
    },
    beforeUnmount() {},
    unmounted() {},
    computed: {},
    methods: {
        openShareModal() {
            let shareData = {
                path: `/event/${this.eventKey}`,
                image: this.eventData.Thumb[0].org_url,
                title: `${this.eventData.e_name} | 밍글패스(Minglepass)`,
                description: "밍글패스(Minglepass)",
                imageId: "",
                shareType: "event",
                detailKey: this.eventKey,
                shareTitle: "share",
            };
            this.$modal.show("share-modal", shareData);
        },
        returnImg(data) {
            if (util.isEmpty(data)) {
                return "";
            } else {
                return data[0].org_url;
            }
        },
        moveTop() {
            let scrollArea = document.getElementById(`scrollArea`);
            scrollArea.scrollTo({
                top: 0,
                behavior: "smooth",
            });
            document.querySelector("#event-detial-content").scrollTo({
                top: 1000,
                behavior: "smooth",
            });
        },
        returnTicketStatus(status) {
            switch (status) {
                case 1:
                    return "stay_buy";
                case 2:
                    return "selling";
                case 3:
                    return "sell_end";
                default:
                    return "stay_buy";
            }
        },

        returnGrade() {
            if (this.eventData.e_grade == "") {
                return this.$t("no3");
            } else {
                return this.eventData.e_grade;
            }
        },
    },
    watch: {},
};
</script>
