<template>
    <div style="display: contents"></div>
</template>
<script>
export default {
    name: 'PaginationNoneLayout',
    mixins: [],
    components: {},
    inject: {},
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeUnmount() {
    },
    unmounted() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>
