<template>
    <div>
        <event-list-component :eventType="'event'"></event-list-component>
        <event-normal-filter-modal></event-normal-filter-modal>
        <non-button-update-sort-modal></non-button-update-sort-modal>
    </div>
</template>


<script>
import PageHeader from "@/components/PageHeader.vue";
import { mapState } from "vuex";
import alertMixins from "@/mixins/alertMixins";
import EventListComponent from '@/components/event/EventListComponent.vue';
import EventNormalFilterModal from '@/components/modal/EventNormalFilterModal.vue';
import NonButtonUpdateSortModal from '@/components/modal/NonButtonUpdateSortModal.vue';

export default {
    name: "Event",
    mixins: [alertMixins],
    components: {
        PageHeader,
        EventListComponent,
        EventNormalFilterModal,
        NonButtonUpdateSortModal,
    },
    props: {},
    data() {
        return {};
    },
    beforeRouterEnter() {},
    created() {},
    mounted() {},
    beforeUnmount() {},
    unmounted() {},
    computed: {
        ...mapState({
            UserInfo: (state) => state.userInfo.UserInfo,
        }),
    },
    methods: {},
    watch: {},
};
</script>
