<template>
  <ion-infinite-scroll
      v-if="paginationData.total > 0 && paginationData.total > paginationData.page * paginationData.size"
      threshold="0px" @ionInfinite="loadMore($event)">
    <ion-infinite-scroll-content
        loadingSpinner="bubbles"
        loadingText="Loading more data...">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</template>
<script>
export default {
  name: "LazyloadDefaultLayout",
  mixins: [],
  inject: ['setPage'],
  components: { },
  props: {
    paginationData: {
      default: () => {
        return {total: 0, page: 0, size: 0,}
      }
    },
    listLength: {
      default: () => {
        return 0
      }
    },
    finishGetList: {default: true,},
  },
  data() {
    return {
      loadingMore : false,
    }

  },
  beforeRouterEnter() {
  },
  created() {
  },
  mounted() {
  },
  beforeUnmount() {
  },
  unmounted() {
  },
  computed: {

  },
  methods: {
    loadMore($event) {
      if (this.loadingMore) {
        return;
      }
      this.loadingMore = true;
      let page = this.paginationData.page;
      page = page + 1;
      this.setPage('page', page);
      this.lazyLoadComplete($event);
    },
    lazyLoadComplete($event) {
      setTimeout(() => {
        if (!this.finishGetList) {
          this.lazyLoadComplete($event);
          return false;
        }

        $event.target.complete();
        this.loadingMore = false;
      }, 500)

    },
  },
  watch: {
  },
}
</script>

<style scoped>

</style>