<template>

</template>


<script>
export default {
    name: "EventFilterNoneLayout",
    mixins: [],
    inject : [],
    components: { },
    props: {
    },
    data() {
        return {
          
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
     
    },
    beforeUnmount() {
    },
    unmounted() {
    },
    computed: {

    },
    methods: {
        
    },
    watch: {
    },
}
</script>
