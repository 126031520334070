<template>

    <div class="point_charge_wrap">
        <div class="point_charge_title">
            {{ $t('mingle_charge') }}
        </div>
        <div class="charge_price_list_box">
            <div class="charge_price_list" v-for="point in pointItems" :key="`cp${point.ibp_idx}`"
                 :class="{active: selectedItem.ibp_idx === point.ibp_idx}" @click="selectItem(point)">
                <!--          <div class="charge_price_list">-->
                <div class="charge_price_top">
                    <div class="charge_bonus_box" v-show="hasBonusPoint(point)">
                        <div class="charge_bonus">
                            <!--                <div class="charge_bonus">-->
                            <span>{{ numFormat(point.ibp_point) }} + {{ numFormat(point.ibp_point_extra) }}</span>
                        </div>
                    </div>
                    <div class="charge_mingle_box" :class="{'no_bonus': !hasBonusPoint(point)}">
                        <!--              <div class="charge_mingle_box">-->
                        <div class="charge_mingle_img"></div>
                        <div class="charge_mingle_point">{{
                                numFormat(point.ibp_point + point.ibp_point_extra)
                            }}Mg
                        </div>
                    </div>
                </div>
                <div class="charge_price_bottom">
                    <div class="charge_price">￦ {{ numFormat(point.i_price) }}</div>
                </div>
            </div>
        </div>

        <!--        <div class="notification_box">-->
        <!--            <ul class="notification_text">-->
        <!--                <li type="disc">관리자페이지에서 입력한 안내사항 노출...</li>-->
        <!--                <li type="disc">관리자페이지에서 입력한 안내사항 노출...</li>-->
        <!--                <li type="disc">관리자페이지에서 입력한 안내사항 노출...</li>-->
        <!--            </ul>-->
        <!--        </div>-->

        <div class="charge_notification_wrap">
            <div class="terms_check" :class="{ active : checkTerms}" @click="changeTerms()"></div>
            <span class="terms_text" v-html="$t('charge_notification_text')"></span>
        </div>
        <div class="charge_btn_wrap">
            <button class="charge_btn" @click="writePoint('card')">
                {{ $t('charge') }}
            </button>
            &nbsp&nbsp&nbsp&nbsp
            <!--            <button class="charge_btn" @click="directOrder('dgm')">{{ $t('charge_force_card') }}</button>-->
        </div>

        <div class="check_validator center mt0" v-if="showError">
            <span>{{ $t('charge_validator_text') }}</span>
        </div>
    </div>
</template>

<script>
import util from "@/mixins/util";
import Payment from "@/components/point/Payment";
import {mapState} from "vuex";
import Order from "@/components/point/Order";
import alertMixins from "@/mixins/alertMixins";
import EventBus from "@/utils/event-bus";
import {getStorageToken} from "@/mixins/Auth";
import {apiDomain} from "@/api/config";
import AuthMixins from "@/mixins/AuthMixins";

let pgPopup = null;
export default {
    name: "PointChargeComponent",
    mixins: [alertMixins, AuthMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            pointItems: [],
            selectedItem: {},
            checkTerms: false,
            showError: false,
            PaymentClass: null,
            OrderClass: null,
            itemId: null,
            qty: null,
            c_type: 'item',
            pg: 'dgm',
            pw: 'dgmong',
            oid: '',
            isRunning: false,
            pgPopup: '',
        }
    },
    beforeRouterEnter() {
    },
    async created() {
        openTerms = this.openTerms

        this.getItemPoint();
        this.PaymentClass = new Payment.PaymentClass(this.UserInfo);
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        getItemPoint() {
            try {
                this.$api.$point.getPointItem().then(res => res.Data.Result).then(res => {
                    if (!util.isEmpty(res)) {
                        this.pointItems = res.List;
                    }
                })
            } catch (e) {
                console.log(e);
            }
        },
        selectItem(item) {
            this.selectedItem = item;
        },
        hasBonusPoint(item) {
            return !util.isEmpty(item.ibp_point_extra) && item.ibp_point_extra > 0;
        },
        numFormat(num) {
            if (isNaN(num)) {
                return 0;
            }
            return util.Number.numFormat(num);
        },
        openTerms(name, type) {
            this.$modal.show(name, {termsType: type})
        },
        changeTerms() {
            this.checkTerms = !this.checkTerms
            this.showError = false;
        },
        //
        async directOrder(pg) {
            if (!this.checkTerms) {
                this.showError = true;
                return false;
            }
            if (util.isEmpty(this.selectedItem.i_idx)) {
                this.errorAlert(this.$t('required_point_item'));
                return false;
            }
            if (this.isRunning) {
                return false;
            }
            this.isRunning = true;
            EventBus.$emit('apiLoading', true);
            this.OrderClass = new Order.OrderClass(this.UserInfo);

            let orderId = await this.PaymentClass.directPay(this.selectedItem.i_idx, 1, 'item');
            if (orderId) {
                //OrderClass.setOrderAddress(addr);                 //추후 이와 같은 형태로..오더는 각각 필요한것들로 쪼개서 저장
                //OrderClass.setOrderPoint(this.payments);          //추후 포인트
                let status = this.OrderClass.OrderPay(orderId, pg);                            //pg에 따른 페이 시작.
                if (status) {
                    this.createAlert({
                        content: this.$t('point_charge_success'),
                        hide: () => {
                            let token = getStorageToken();
                            this.$store.dispatch('userInfo/setUserInfo', {token});
                            this.$router.push('/mypage/point')
                        }
                    })
                    //go 주문내역..
                } else {
                    //alert ㅈ문실패
                    console.log('fail payment')
                    this.errorAlert(this.$t('point_charge_err1'));
                }
                EventBus.$emit('apiLoading', false);
                this.isRunning = false;

            } else {
                //alert
                //시스템 에러...관리자 문의.....
                console.log('system error')
                this.errorAlert(this.$t('point_charge_err2') + '<br>' + this.$t('point_charge_err3'));
                EventBus.$emit('apiLoading', false);
                this.isRunning = false;
            }


        },

        async writePoint(type) {
            if (!this.isAuthenticated()) {
                this.createConfirm({
                    content: `${this.$t('check_deposit_auth1')}<br>${this.$t('check_deposit_auth2')}`,
                    confirmText: this.$t('check_now'),
                    confirm: () => {
                        this.$router.push('/mypage/update')
                    },
                })
            } else {
                if (!this.checkTerms) {
                    this.showError = true;
                    return false;
                }
                if (util.isEmpty(this.selectedItem.i_idx)) {
                    this.errorAlert(this.$t('required_point_item'));
                    return false;
                }
                if (this.isRunning) {
                    return false;
                }
                this.isRunning = true;
                EventBus.$emit('apiLoading', true);

                this.oid = await this.PaymentClass.directPay(this.selectedItem.i_idx, 1, 'item');
                console.log(this.oid);
                if (this.oid) {
                    this.writePayment(this.oid, type);
                } else {
                    //카트 생성 에러
                    console.log('system error')
                    this.errorAlert(this.$t('point_charge_err2') + '<br>' + this.$t('point_charge_err3'));
                    EventBus.$emit('apiLoading', false);
                    this.isRunning = false;
                }
            }
        },
        async writePayment(oid, type) {
            this.OrderClass = new Order.OrderClass(this.UserInfo);
            let isSafari = await this.PaymentClass.getBrowserType();
            let pgUrl = await this.OrderClass.OrderPay(this.oid, type);
            if (util.isEmpty(pgUrl)) {
                console.log('system error')
                this.errorAlert(this.$t('point_charge_err2') + '<br>' + this.$t('point_charge_err3'));
                EventBus.$emit('apiLoading', false);
                this.isRunning = false;
            }
            console.log(isSafari);

            if (isSafari === 'notSafari') {
              pgPopup = window.open(pgUrl, 'popupChk', 'width=1000,height=800, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
                this.checkPopupClose();
            } else {
                if (!util.isEmpty(pgUrl)) {
                    window.location.replace(pgUrl);
                }
                return
            }

        },
        checkPopupClose() {
            let $this = this;
            EventBus.$emit('apiLoading', true);
            let checkPgPopupInterval = setInterval(() => {
                if (pgPopup.closed) {
                    clearInterval(checkPgPopupInterval);
                    $this.checkOrderId(this.oid).then(result => {
                        if (result) {
                            $this.writeOrder($this.o_id);
                        } else {
                            EventBus.$emit('apiLoading', false);
                            this.isRunning = false;
                        }
                    });
                }
            }, 1000)
        },
        async writeOrder() {
            let updateUserInfo = this.UserInfo;
            updateUserInfo.point = Number(this.UserInfo.point) + Number(this.selectedItem.ibp_point);

            // let status = this.OrderClass.writePoint(this.oid);
            // if (status) {
            EventBus.$emit('apiLoading', false);
            this.$store.dispatch('userInfo/changeUserInfo', {data: updateUserInfo});
            this.createAlert({
                content: this.$t('pay_point_success'),
                // btnText: '확인',
                btnText: this.$t('yes'),
                hide: () => {

                    this.$router.push('/mypage/point');
                }
            })
        },
        // } else {
        //     this.errorAlert(this.$t('point_charge_err2') + '<br>' + this.$t('point_charge_err3'));
        //     EventBus.$emit('apiLoading', false);
        //     this.isRunning = false;
        // }
    async checkOrderId(o_id) {
        let result = await this.OrderClass.checkOrder(o_id);
        if (!result) {
            let errorMsg = this.$t('payment_fail');
            this.errorAlert(errorMsg)
            return false;
        }
        return true;
    },

}
,
watch: {
}
,
}
</script>

<style scoped>

</style>
