<template>
    <div class="container-list">
        <div class="wrap-list-event" v-for="(item, index) in eventData" :key="`swiper${index}`"
             @click="$router.push(`/event/${item.e_idx}`)">
            <div class="wrap-img-event" :style="`background-image : url('${returnImg(item)}')`">
                <div class="text-dday">{{ returnDday(item.e_end_date) }}</div>
                <div class="text-info">{{ item.ec_name }}</div>
            </div>
            <div class="wrap-text-event">
                <div class="text-title">{{ item.e_name }}</div>
                <div class="text-info mb0">{{ item.e_address_name }}</div>
                <div class="text-info">{{ returnDateFormat(item.e_start_date) }} - {{
                        returnDateFormat(item.e_end_date)
                    }}
                </div>
                <div class="wrap-info-ticket">
                    <div class="icon-ticket"></div>
                    <div class="text-count">{{ item.u_publish_total }}</div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

import DateMixins from "@/mixins/dateMixins";
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";

export default {
    name: "EventListDirectorLayout",
    mixins: [DateMixins, imageOption],
    components: {},
    props: {
        eventData: {
            default: () => []
        },

    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeUnmount() {
    },
    unmounted() {
    },
    computed: {},
    methods: {
        returnImg(data) {
            if (!util.isEmpty(data.Thumb) && !util.isEmpty(data.Thumb[0])) {
                return `${data.Thumb[0].org_url}?${this.setImageOptions(150, 153)}`;

            } else {
                return '';
            }
        },
        returnTicketStatus(status) {
            switch (status) {
                case 1:
                    return 'stay_buy';
                case 2:
                    return 'selling';
                case 3:
                    return 'sell_end';
                default:
                    return 'stay_buy';
            }
        },
        returnDday(date) {
            let dday = this.getDday(date);
            if (dday > 0) {
                return `D-${dday}`;
            } else if (dday === 0) {
                return 'D-DAY';
            } else {
                return `D+${Math.abs(dday)}`;
            }
        },
    },
    watch: {},
}
</script>
