<template>

    <utility-list-component :collectKey="collectKey" :utility-type="'utilityCollections'"></utility-list-component>

</template>


<script>


import UtilityListComponent from "@/components/utility/UtilityListComponent.vue";
export default {
    name: "UtilityDetailUtil",
    mixins: [],
    components: {UtilityListComponent },
    props: {

    },
    data() {
        return {
            collectKey: this.$route.params.idx,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {

    },
    methods: {
    },
    watch: {
    },
}
</script>
