import Vue from 'vue';
import {request, setAuthInHeader} from "@/api/request";
// import {req} from "vuelidate/src/validators/common";


Vue.prototype.$api = {
    $auth: { // 계정 로그인 / 회원가입 api
        login(params) {
            return request.post(`member/login`, params).then(({data}) => data);
        },
        findPw(params) {
            return request.post('member/send/passwd', params).then(({data}) => data);
        },
        googleLogin(params) {
            return request.post('sns/google/code', params).then(({data}) => data);
        },
        facebookLogin(params) {
            return request.post('sns/facebook/code', params).then(({data}) => data);
        },
        naverLogin(params) {
            return request.post(`sns/naver/code`, params).then(({data}) => data);
        },
        kakaoLogin(params) {
            return request.post(`sns/kakao/code`, params).then(({data}) => data);
        },
        appleLogin(params){
            return request.post(`sns/apple/code`, params).then(({data}) => data);
        },
        deleteApple(params){
            return request.post(`sns/apple/revoke`, params).then(({data}) => data);
        },
    },
    $handler: {
        authHeader : setAuthInHeader
    },
    $member: {
        getList(params) {
            return request.get('member', params).then(({data}) => data);
        },
        getMainList(params) {
            return request.get('member/main', params).then(({data}) => data);
        },
        getInfo(params) {
            return request.get('member/info', params).then(({data}) => data);
        },
        getMember(params) {
            return request.get('member', params).then(({data}) => data);
        },
        checkEmailDuplicate(params) {
            return request.get('member/chk/id', params).then(({data}) => data);
        },
        checkNickDuplicate(params) {
            return request.get('member/chk/nick', params).then(({data}) => data);
        },
        createMember(params) {
            return request.post('member', params).then(({data}) => data);
        },
        updateMember(params) {
            return request.post('member/update', params).then(({data}) => data);
        },
        updateSocialMember(params) {
            return request.post('member/social/update', params).then(({data}) => data);
        },
        updateRecommend(params) {
            return request.post('member/service/recommend', params).then(({data}) => data);
        },
        createAccount(params) {
            return request.post('member/account', params).then(({data}) => data);
        },
        deleteAccount(params) {
            return request.post('member/account/delete', params).then(({data}) => data);
        },
        updateNick(params) {
            return request.post('member/service/change/ni', params).then(({data}) => data);
        },
        updatePwd(params) {
            return request.post('member/service/change/pwd', params).then(({data}) => data);
        },
        sendMail(params) {
            return request.post('member/send/certify', params).then(({data}) => data);
        },
        getMail(params) {
            return request.post('member/get/certify', params).then(({data}) => data);
        },
        getProfile(params) {
            return request.get('member/profile', params).then(({data}) => data);
        },
        toggleLike(params) {
            return request.post(`follow/like/toggle`, params).then(({data}) => data);
        },
        updateProfile(params) {
            return request.post(`member/profile/update`, params).then(({data}) => data);
        },
        getCreatorLike(params) {
            return request.get(`follow/like`, params).then(({data}) => data);
        },
        deleteMember(params) {
            return request.post(`member/delete`, params).then(({data}) => data);
        },
        getEncryptData(params) {
            return request.post(`member/nice/encrypt`, params).then(({data}) => data);
        },
        getDecryptData(params) {
            return request.post(`member/nice/decrypt`, params).then(({data}) => data);
        },
        getNiceEncodeToken(params) {
            return request.post(`member/nice/token`, params).then(({data}) => data);
        },
    },
    $board: {
        getNotice(params) {
            return request.get("board/notice", params).then(({data}) => data);
        },
        getQna(params) {
            return request.get("board/qna", params).then(({data}) => data);
        },
        createQna(params) {
            return request.post("board/qna", params).then(({data}) => data);
        },
        updateQna(params) {
            return request.post('board/qna/update', params).then(({data}) => data);
        },
        deleteQna(params) {
            return request.post("board/qna/delete", params).then(({data}) => data);
        },
        getCategory(params) {
            return request.get(`board/faq/category`, params).then(({data}) => data);
        },
        getFaq(params) {
            return request.get(`board/faq`, params).then(({data}) => data);
        },
    },
    $alarm: {
        getAlarm(params) {
            return request.get('alarm/member', params).then(({data}) => data);
        },
        readAlarm(params) {
            return request.post('alarm/member/read', params).then(({data}) => data);
        },
        deleteAlarm(params) {
            return request.post('alarm/member/delete', params).then(({data}) => data);
        }
    },
    $config: {
        getGroup(params) {
            return request.get(`group`, params).then(({data}) => data);
        },
        getBanner(params) {
            return request.get(`banner`, params).then(({data}) => data);
        },

    },
    $item: {
        getItem(params) {
            return request.get(`item`, params).then(({data}) => data);
        },
        getItemDetail(params) {
            return request.get(`item/find`, params).then(({data}) => data);
        },
        getItemBanner(params) {
            return request.get(`item/views`, params).then(({data}) => data);
        },
        createItem(params) {
            return request.post(`item`, params).then(({data}) => data);
        },
        getItemCategory(params) {
            return request.get(`itemcategory`, params).then(({data}) => data);
        },
        updateItem(params) {
            return request.post(`item/update`, params).then(({data}) => data);
        },
        deleteItem(params) {
            return request.post(`item/delete`, params).then(({data}) => data);
        },
        getItemDetect(params) {
            return request.get(`item/detect`, params).then(({data}) => data);
        },
        getItemLike(params) {
            return request.get(`item/like`, params).then(({data}) => data);
        },
        itemLike(params) {
            return request.post(`item/like`, params).then(({data}) => data);
        },
        getEntryDetail(params) {
            return request.get(`contest/item/detail`, params).then(({data}) => data);
        },
        getCompetitionItem(params) {
            return request.get(`contest/item`, params).then(({data}) => data);
        },
        buyItem(params) {
            return request.post(`market/buy`, params).then(({data}) => data);
        },
        getAuction(params) {
            return request.get(`market/my/auction`, params).then(({data}) => data);
        },
        getBid(params) {
            return request.get(`market/my/bids`, params).then(({data}) => data);
        },
        getBidHistory(params) {
            return request.get(`market/bid`, params).then(({data}) => data);
        },
        getMarket(params) {
            return request.get(`market`, params).then(({data}) => data);
        },
        getItemStore(params) {
            return request.get(`item/store`, params).then(({data}) => data);
        },
        getMyItem(params) {
            return request.get(`item/store`, params).then(({data}) => data);
        },
        getOrder(params) {
            return request.get(`market/order`, params).then(({data}) => data);
        },
        getItemHistory(params) {
            return request.get(`item/history`, params).then(({data}) => data);
        },
        getRealBuy(params) {
            return request.get(`market/real/buy`, params).then(({data}) => data);
        },
        getRealSale(params) {
            return request.get(`market/real/sale`, params).then(({data}) => data);
        },
        setAssume(params) {
            return request.post(`market/assume`, params).then(({data}) => data);
        },
        addHash(params) {
            return request.post(`item/nft/add`, params).then(({data}) => data);
        },
        failHash(params) {
            return request.post(`item/nft/fail`, params).then(({data}) => data);
        },
        getItemNftHistory(params) {
            return request.get(`item/nft/history`, params).then(({data}) => data);
        },
        marketPriceUpdate(params) {
            return request.post(`market/price/update`, params).then(({data}) => data);
        },
        getItemCombine(params) {
            return request.get(`item/combine`, params).then(({data}) => data);
        },
        getEdition(params) {
            return request.get(`item/editions`, params).then(({data}) => data);
        },
        giftItem(params) {
            return request.post(`item/nft/gift`, params).then(({data}) => data);
        },
    },
    $collect:{
        getCollection(params) {
            return request.get(`collect`, params).then(({data}) => data);
        },
        createCollect(params){
            return request.post(`collect`, params).then(({data}) => data);
        },
        updateCollect(params){
            return request.post(`collect/update`, params).then(({data}) => data);
        },
        collectLike(params) {
            return request.post(`collect/like`, params).then(({data}) => data);
        },
    },
    $terms: {
        getTerms(params) {
            return request.get(`config`, params).then(({data}) => data);
        },

    },
    $detection: {
        getDetection(params) {
            return request.get(`lawsuit`, params).then(({data}) => data);
        },
        itemDetect(params) {
            return request.post(`detect`, params).then(({data}) => data);
        },
        itemAllDetect(params) {
            return request.post(`detect/all`, params).then(({data}) => data);
        },
        getItemDetectDetail(params) {
            let i_idx = params.i_idx;
            if (params.hasOwnProperty('i_idx')) {
                delete params.i_idx;
            }
            return request.get(`detect/item/${i_idx}`, params).then(({data}) => data);
        },
        getDetectResult(params) {
            let d_idx = params.d_idx;
            if (params.hasOwnProperty('d_idx')) {
                delete params.d_idx;
            }
            return request.get(`detect/result/list/${d_idx}`, params).then(({data}) => data);
        },
        changeStatus(params) {
            return request.post(`detect/result/update/status`, params).then(({data}) => data);
        },
        updateDetectDomainIgnore(params) {
            return request.post(`detect/result/update/except`, params).then(({data}) => data);
        },
        getRequestDetail(params) {
            let l_idx = params.l_idx;
            if (params.hasOwnProperty('l_idx')) {
                delete params.l_idx;
            }
            return request.get(`lawsuit/detail/${l_idx}`, params).then(({data}) => data);
        },
        getIgnoreUrl(params) {
            let d_idx = params.d_idx;
            if (params.hasOwnProperty('d_idx')) {
                delete params.d_idx;
            }
            return request.get(`detect/ignore/${d_idx}`, params).then(({data}) => data);
        },
        createIgnoreUrl(params) {
            return request.post(`detect/ignore`, params).then(({data}) => data);
        },
        deleteIgnoreUrl(params) {
            return request.post(`detect/ignore/delete`, params).then(({data}) => data);
        },
        createDetectLaw(params) {
            return request.post(`lawsuit`, params).then(({data}) => data);
        },

    },
    $competition: {
        getCompetition(params) {
            return request.get(`contest`, params).then(({data}) => data);
        },
        getCompetitionCategory(params) {
            return request.get(`contest/category`, params).then(({data}) => data);
        },
        getMainCompetition(params) {
            return request.get(`contest/main`, params).then(({data}) => data);
        },
        createCompetition(params) {
            return request.post(`contest`, params).then(({data}) => data);
        },
        getCompetitionDetail(params) {
            return request.get(`contest/detail`, params).then(({data}) => data);
        },
        updateCompetition(params) {
            return request.post(`contest/update`, params).then(({data}) => data);
        },
        getCompetitionItem(params) {
            return request.get(`contest/item`, params).then(({data}) => data);
        },
        competitionLike(params) {
            return request.post(`contest/like`, params).then(({data}) => data);
        },
        deleteCompetition(params) {
            return request.post(`contest/delete`, params).then(({data}) => data);
        },
        writeEntry(params) {
            return request.post(`contest/item`, params).then(({data}) => data);
        },
        voteItem(params) {
            return request.post(`contest/item/vote`, params).then(({data}) => data);
        },
        createJudge(params) {
            return request.post(`contest/item/judge`, params).then(({data}) => data);
        },
        writeAward(params) {
            return request.post(`contest/item/prize`, params).then(({data}) => data);
        },
        getAward(params) {
            return request.get(`contest/item/prize`, params).then(({data}) => data);
        },
        getAwardResult(params) {
            return request.get(`contest/item/judge`, params).then(({data}) => data);
        },
        getCompetitionJudge(params) {
            return request.get(`contest/judge`, params).then(({data}) => data);
        },
        getPrizeMyCompetition(params) {
            return request.get(`contest/item/prize/my`, params).then(({data}) => data);
        },
        getCompetitionEntry(params) {
            return request.get(`contest/item/my`, params).then(({data}) => data);
        },
        deleteCompetitionEntryItem(params) {
            return request.post(`contest/item/delete`, params).then(({data}) => data);
        },
        checkApply(params) {
            return request.get('contest/item/chk', params).then(({data}) => data);
        },
        getCompetitionLike(params) {
            return request.get(`contest/like`, params).then(({data}) => data);
        },
        createComment(params) {
            return request.post(`contest/comment`, params).then(({data}) => data);
        },
        getComment(params) {
            return request.get(`contest/comment`, params).then(({data}) => data);
        },
        deleteComment(params) {
            return request.post(`contest/comment/delete`, params).then(({data}) => data);
        },
        updateComment(params) {
            return request.post(`contest/comment/update`, params).then(({data}) => data);
        },

    },
    $curation: {
        getMyCurator(params) {
            return request.get(`curator/my`, params).then(({data}) => data);
        },
        checkApplyCurator(params) {
            return request.get(`curator/chk`, params).then(({data}) => data);
        },
        applicationCurator(params) {
            return request.post(`curator`, params).then(({data}) => data);
        },
        cancelCurator(params) {
            return request.post(`curator/cancel`, params).then(({data}) => data);
        },
        createCuration(params) {
            return request.post(`item/curation`, params).then(({data}) => data);
        },
        getCuration(params) {
            return request.get(`item/curation`, params).then(({data}) => data);
        },
        cancelCuration(params) {
            return request.post(`item/curation/delete`, params).then(({data}) => data);
        },
        allowCuration(params) {
            return request.post(`item/curation/allow`, params).then(({data}) => data);
        },
        rejectCuration(params) {
            return request.post(`item/curation/reject`, params).then(({data}) => data);
        },
        getCurationHistory(params) {
            return request.get(`item/curation/history`, params).then(({data}) => data);
        },
        getCurationRequestItem(params) {
            return request.get(`item/curation/request`, params).then(({data}) => data);
        },
        getCurationAuctionItem(params) {
            return request.get(`item/curation/auction`, params).then(({data}) => data);
        },
        abandonCuration(params) {
            return request.post(`item/curation/abandon`, params).then(({data}) => data);
        },
    },
    $gallery: {
        getCollection(params) {
            return request.get(`collection`, params).then(({data}) => data);
        },
        createCollection(params) {
            return request.post(`collection`, params).then(({data}) => data);
        },
        updateCollection(params) {
            return request.post(`collection/update`, params).then(({data}) => data);
        },
        deleteCollection(params) {
            return request.post(`collection/delete`, params).then(({data}) => data);
        },
        getCollectionItem(params) {
            return request.get(`collection/item`, params).then(({data}) => data);
        },
        deleteCollectionItem(params) {
            return request.post(`collection/item/delete`, params).then(({data}) => data);
        },
        createCollectionItem(params) {
            return request.post(`collection/item`, params).then(({data}) => data);
        },

    },
    $point: {
        getPointDefine(params) {
            return request.get(`point/define`, params).then(({data}) => data);
        },
        updatePointDetect(params) {
            return request.post(`point/define/update`, params).then(({data}) => data);
        },
        PointWithdraw(params) {
            return request.post(`point/withdraw`, params).then(({data}) => data);
        },
        getPointItem(params) {
            return request.get(`itempoint`, params).then(({data}) => data);
        },
        getPointDetail(params) {
            return request.get(`point/detail`, params).then(({data}) => data);
        },
        getPointHistory(params) {
            return request.get(`point`, params).then(({data}) => data);
        },
        createDeposit(params) {
            return request.post('deposit', params).then(({data}) => data);
        },
    },
    $pay: {
        createCart(params) {
            return request.post(`cart`, params).then(({data}) => data);
        },
        createOrderPoint(params) {
            return request.post(`orderpoint/pay`, params).then(({data}) => data);
        },
        checkOrder(params) {
            return request.post(`orderpoint/chk/oid`, params).then(({data}) => data);
        },
        writePoint(params) {
            return request.post(`orderpoint`, params).then(({data}) => data);
        },

    },
    $file: {
        getFile(params) {
            return request.get("file", params).then(({data}) => data);
        },
        editorUpload(params) {
            return request.post("file/editorUpload", params).then(({data}) => data);
        },
        download(params) {
            return request.get("download", params).then(({data}) => data);
        },
        copyFile(params) {
            return request.post(`file/copy`, params).then(({data}) => data);
        }
    },
    $utility: {
        getUtility(params) {
            return request.get("utility", params).then(({data}) => data);
        },
        getNticket(params) {
            return request.get("utility/sell/user", params).then(({data}) => data);
        },
        getConnectUtility(params) {
            return request.get("utility/connect/item", params).then(({data}) => data);
        },
        getCollectionsUtility(params) {
            return request.get("utility/connect/collections", params).then(({data}) => data);
        }
    },
    $nticket:{
        getLaunchUrl(params){
            return request.launchGet(`getPuchaseLinkList.do`,params).then(({data})=>data);
        }
    },


    $event:{
        getEventHome(params) {
            return request.get('event', params).then(({data}) => data);
        },
        getEventOngoing(params) {
            return request.get('event/ongoing', params).then(({data}) => data);
        },
        getEventCategory(params) {
            return request.get('event/category', params).then(({data}) => data);
        },
        getEventRecommend(params) {
            return request.get('recommend/event', params).then(({data}) => data);
        },
        toggleEventLikeBtn(params) {
            return request.post(`event/like`, params).then(({data}) => data);
        },
    },
};

export default Vue.prototype.$api;
