import 'babel-polyfill'
import 'es6-promise/auto'
import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import _ from "lodash";
import API from '@/api/index'
import moment from 'moment';
import VueMomentJs from 'vue-momentjs';
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/ko'
import 'element-ui/lib/theme-chalk/index.css'
import VModal from 'vue-js-modal';
import 'expose-loader?$!expose-loader?jQuery!jquery'
import Storage from 'vue-web-storage';
import i18n from './i18n';
import Meta from 'vue-meta';
import InfiniteLoading from "vue-infinite-loading";
import GAuth from 'vue-google-oauth2'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import * as VueGoogleMaps from 'vue2-google-maps';
const gauthOption = {
    clientId: '16176604297-kjgbfb5dsv3sc9bndrgc24b3koe4acpu.apps.googleusercontent.com',
    scope: 'profile email',
    prompt: 'select_account'
}
Vue.use(GAuth, gauthOption)
window.$ = require('jquery')


Vue.use(Meta, {
    keyName: 'metaInfo',
    attribute: 'data-vue-meta',
    ssrAttribute: 'data-vue-meta-server-rendered',
    tagIDKeyName: 'vmid',  // 변화시키는 구분 값
    refreshOnceOnNavigation: true
})
Vue.use(VueMomentJs, moment);
Vue.use(VModal);
Vue.use(API)
Vue.use(InfiniteLoading);
//Vue.use(ElementUI, {locale});
Vue.use(ElementUI, {
    i18n: (key, value) => i18n.t(key, value)
})
Object.defineProperty(Vue.prototype, "$_", { value: _ });
Vue.config.productionTip = false;
Vue.use(Storage, {
    prefix: 'com.dgmonglab.blockchain_wep', // default `app_`
    drivers: ['local'], // default 'local'
});


let apiKey = "AIzaSyBzRKLzbI0Aw2Vk0OzwPxSw5PsXs-ONrHo"

Vue.use(VueGoogleMaps, {
    load: {
        key: apiKey,
        language : "ko",
        libraries: 'places',
    },
})


new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')


