<template>
    <span>
        <button class="naver_login_wrap" @click="triggerNaver">
            <a style="display:none;" id="naverIdLogin"></a>
            <img src="@/assets/image/main/naver.png">
        </button>
    </span>
</template>

<script>
import {naverService} from "@/plugins/naverService";
export default {
    name: "NaverLoginComponent",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        naverService().setNaver();
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
        triggerNaver() {
            document.querySelector("#naverIdLogin #naverIdLogin_loginButton").click();
        },
        naverSignIn() {
            // naverService().getUserInfo()
            // var url = 'https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id='+this.client_id+'&redirect_uri='+this.callbackUrl+'&state=1234';
            // window.location.replace(url);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
