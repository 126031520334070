<template>
    <div class="search_filter_wrap">
        <div class="search_item_wrap">
            <div class="filter_category_box">
                <el-select id="category" v-model="searchInputOption" class="my_filter select"
                           popper-class="category_select"
                           :popper-append-to-body="false"
                >
                    <el-option :value="'u_name'" :label="$t('subject')"></el-option>
                    <el-option :value="'dir_name'" :label="$t('director_name')"></el-option>
                    <el-option :value="'dir_nick'" :label="$t('director_nick')"></el-option>
                </el-select>
            </div>
            <div class="search_input">
                <el-input :placeholder="$t('st')" v-model="searchInput"
                          @input="setData('searchInput',searchInput)"
                          @keyup.native.enter="setFilterFunc(searchInputOption,searchInput)"/>
                <button class="search_btn"  @click="setFilterFunc(searchInputOption,searchInput)">
                    <span>{{ $t('search') }}</span>
                    <img src="@/assets/image/main/search_btn.png"></button>
            </div>
            <div class="end_filter">
                <el-popover
                        popper-class="top_500"
                        placement=""
                        width="435"
                        trigger="click"
                        :open-delay="300"
                        :offset="0"
                        :append-to-body="false"
                        :popper-options="{
              boundariesElement: 'body',
              gpuAcceleration: true,
              positionFixed: true,
              preventOverflow: true,
            }">
                    <el-button slot="reference" class="filter_btn two"></el-button>
                    <div class="popup_tail"></div>
                    <div class="popup_bg popup_bg_radius">
                        <div class="popup_inner">
                            <div class="inner_top"><span class="title">{{ $t('filter') }}</span></div>
                            <div class="inner_middle">
<!--                                <div class="sub_title">{{ $t('category') }}</div>
                                <div class="edition_wrap">
                                    <el-checkbox-group v-model="filterCategory"
                                                       @change="setFilterFunc('uc_idx', filterCategory)">
                                        <el-checkbox v-for="data in utilityCategoryList" :label="data.uc_idx">{{ data.uc_name }}</el-checkbox>
                                    </el-checkbox-group>
                                </div>-->
                                <div class="sub_title">{{ $t('status') }}</div>
                                <div class="status_wrap">
                                    <el-checkbox-group  v-model="filterStatus"
                                                        @change="setFilterFunc('usest', filterStatus)">
                                        <el-checkbox :label="1">
                                            {{ $t('use_await') }}
                                        </el-checkbox>
                                        <el-checkbox :label="0">
                                            {{ $t('use_possible') }}
                                        </el-checkbox>
                                        <el-checkbox :label="2">
                                            {{ $t('use_end_already') }}
                                        </el-checkbox>
                                    </el-checkbox-group>
                                </div>
                                <div class="sub_title">{{ $t('sell_status') }}</div>
                                <div class="type_wrap">
                                    <el-checkbox-group v-model="filterType"
                                                       @change="setFilterFunc('sellst', filterType)">
                                        <el-checkbox :label="1">{{ $t('stay_buy') }}</el-checkbox>
                                        <el-checkbox :label="0">{{ $t('ing_buy') }}</el-checkbox>
                                        <el-checkbox :label="2">{{ $t('end_buy') }}</el-checkbox>
                                    </el-checkbox-group>
                                </div>
                                <div class="sub_title">{{ $t('price2') }}</div>
                                <div class="price_wrap mb10">
                                    <el-input class="el_price_input el_input_num" type="text"
                                              oninput="value = value.replace(/[^0-9.]/g,'')"
                                              v-model="priceInput[0]"></el-input>
                                    <span> Mg</span>
                                    <span class="first_input_step">~</span>
                                    <el-input class="el_price_input el_input_num" type="text"
                                              oninput="value = value.replace(/[^0-9.]/g,'')"
                                              v-model="priceInput[1]"></el-input>
                                    <span> Mg </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="wrapper_bottom" @click="changeFilter()">
                        <button class="">{{ $t('apply2') }}</button>
                    </div>
                </el-popover>
            </div>
            <div class="filter_sort_box">
                <el-select id="sort_select" v-model="sortOption" class="sort_filter select"
                           popper-class="category_select"
                           :popper-append-to-body="false"
                           @change="sortChange(sortOption)">
                    <el-option :value="'last_update'" :label="$t('b_update')"></el-option>
                    <el-option :value="'im_real_hit'" :label="$t('b_enter_m')"></el-option>
                    <el-option :value="'im_like'" :label="$t('b_like')"></el-option>
                    <el-option :value="'priceDesc'" :label="$t('priceDesc')"></el-option>
                    <el-option :value="'priceAsc'" :label="$t('priceAsc')"></el-option>
                </el-select>
            </div>
        </div>
    </div>
</template>

<script>
import UtilityUtil from "@/components/utility/UtilityUtil";
import util from "@/mixins/util";
import {mapState} from "vuex";

const {filterConfig} = UtilityUtil
export default {
    name: "UtilityFilterDefaultLayout",
    mixins: [UtilityUtil],
    components: {},
    inject: ['setFilter', 'setSort', 'setMultiFilter'],
    provide() {
        return {}
    },
    props: {
        filterData: {
            default: () => {
            }
        },
        sortData: {
            type: Object,
            default: () => {
            }
        },
    },
    data() {
        return {
            searchInputOption: 'u_name',
            searchInput: '',
            filterCategory: [],
            filterStatus: [],
            filterType: [],
            priceInput: ['', ''],
            sortOption: 'last_update',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.setterFilterDataInData();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            utilityCategoryList: state => state.utilityCategory.utilityCategoryList,
        })
    },
    methods: {
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
        },
        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },
        setData(key, value) {
            this[key] = value;
        },
        changeFilter() {
            if (this.priceInput[1] !== '' && this.priceInput[0] > this.priceInput[1]) {
                return
            }
            let params = {
                'minprice': this.priceInput[0],
                'maxprice': this.priceInput[1],
            }
            this.setMultiFilter(params)
        },
        setterSortDataInData() {
            this.sort = this.sortData.order;
            this.sortOption = this.sortData.key
            if (this.sortData.key === 'price') {
                if (this.sortData.order === 'desc') {
                    this.sortOption = `priceDesc`
                } else if (this.sortData.order === 'asc') {
                    this.sortOption = `priceAsc`
                }
            }
        },
        sortChange(data) {
            if (data === 'last_update') {
                this.sort = 'desc'
                this.setSort('final_update', 'desc');
            } else if (data === 'im_real_hit') {
                this.sort = 'desc'
                this.setSort('hit', 'desc');
            } else if (data === 'im_like') {
                this.sort = 'desc'
                this.setSort('like_cnt', 'desc');
            } else if (data === 'priceDesc') {
                this.sort = 'desc'
                this.setSort('price', 'desc');
            } else if (data === 'priceAsc') {
                this.sort = 'asc'
                this.setSort('price', 'asc');
            } else {
                this.sort = 'desc'
                this.setSort('final_update', 'desc');
            }
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
