<template>
    <modal name="utility-detail-modal" class="utility-modal"
           :width="`600px`" :height="`70%`" :pivotY="0.5"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div class="h-100 box-flex vertical">
            <div class="back_btn fs-0 ml-auto" @click="modalClose"></div>
            <div class="box-flex vertical of-auto fg-1 child-fs-0 a-stertch w-100 pa16">
                <div class="title-u-modal">
                    {{ $t('purchase_guide') }}
                </div>
                <div v-html="$t('purchase_msg')" class="ta-center mb24">

                </div>
                <div class="name-u-modal mb24">
                    {{ $t('main_collection') }}
                </div>
                <div class="box-flex mb12" @click="moveRouter(utilityData.Collections.Main[0].c_idx)">
                    <div class="img-profile-collection" v-if="utilityData.Collections"
                         :style="`background-image : url('${returnImg(utilityData.Collections.Main[0].MainFile[0])}')`"
                    ></div>
                    <div class="text-collection" >
                        {{ utilityData.c_title }}

                    </div>
                    <div class="icon-arrow-right"></div>
                </div>

                <div class="name-u-modal mb24">
                    {{ $t('dmember') }}
                </div>

                <div class="list_item" @click="moveRouter(testData)">
                    <div class="thumbnail" :style="` background-image: url(${returnThumbnail(testData)});`"
                         >
                        <div class="card_category cc_category">
                            {{ testData.ic_name_eng }}
                        </div>
                        <div class="card_category cc_real" v-if="testData.i_type ==='real'">
                            {{ $t('real') }}
                        </div>
                        <div class="card_category cc_real play_icon"
                             v-if="testData.im_type === 'video'|| testData.im_type==='audio'">
                            <img class="icon play" src="@/assets/image/main/icon_play.png" alt="">
                        </div>
                    </div>
                    <div class="card_detail">
                        <div class="profile_group">
                            <!--                        컬렉션-->
                            <img class="card_profile" :src="returnCollectImage(testData)">

                        </div>
                        <div class="icon_group">
                            <div class="icon_wrap pr-10">
                                <div class="like_img" :class="{'active': isMyLike(testData)}"
                                     ></div>
                                <div class="icon_count" >{{ testData.im_like }}</div>
                            </div>
                            <div class="icon_wrap">
                                <img class="icon eye" src="@/assets/image/main/eye_empty.png" alt="">
                                <div class="icon_count">{{ testData.im_real_hit }}</div>
                            </div>
                        </div>
                        <div class="card_title card_tit_home">{{ testData.i_name }}</div>
                        <div class="premium" v-if="checkPremium(testData)">Premium</div>
                        <div class="item_status time">{{ returnItemType(testData) }}</div>
                        <div class="item_price">
                            <!--                        <img class="item_small_logo" src="@/assets/image/main/small_logo.png" alt="">-->
                            <span class="price">{{ returnCommas(testData.i_price) }} Mg </span><br/>
                            <span class="item_small">({{ returnMingle(testData.i_price) }})</span>
                        </div>
                    </div>
                </div>


                <div class="name-u-modal mb24">
                    {{ $t('connect_collection') }}
                </div>
                <div class="box-flex mb12" @click="moveRouter(data.c_idx)"
                     v-for="(data,index) in utilityData.Collections.Sub" :key="`${index}subImageModal`"
                     v-if="utilityData.Collections && utilityData.Collections.Sub.length > 0">
                    <div class="img-profile-collection" :style="`background-image : url('${returnImg(data.MainFile[0])}')`"></div>
                    <div class="text-collection">
                        {{ data.c_title }}
                    </div>
                    <div class="icon-arrow-right"></div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import imageOption from "@/mixins/imageOption";
import util from "@/mixins/util";
import alertMixins from "@/mixins/alertMixins";
import EventBus from "@/utils/event-bus";
import priceMixins from "@/mixins/priceMixins";
import imageResizeMixins from "@/mixins/imageResizeMixins";

export default {
    name: "UtilityDetailModal",
    mixins: [imageOption, alertMixins, priceMixins,imageResizeMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            utilityData: {},

            testData: {
                "i_idx": 25087,
                "i_sku": "e53da56f-e365-468e-ba26-91d3e1b71d74",
                "i_type": "digital",
                "i_name": "골프왕아이템",
                "i_detail": "",
                "i_price": 100,
                "i_price_org": 0,
                "i_use": 1,
                "i_sales": 0,
                "i_status": "license",
                "i_memo": "",
                "i_hashId": "fcd2dd2ef3b050401afc56ac5b6d22d55fcfd4c04ca1987ec6496b6d77caf234",
                "updated_at": "2024-09-20 12:02:21",
                "created_at": "2024-09-20 12:02:17",
                "im_real_hit": 0,
                "im_real_like": 0,
                "im_type": "photo",
                "im_status": 3,
                "im_adult_chk": 0,
                "im_originator": 62,
                "im_edition": 0,
                "im_count": null,
                "im_total": null,
                "im_enroll": 1,
                "im_hit": 16,
                "im_like": 0,
                "im_possession_date": "2024-09-20 12:02:17",
                "im_nft": 1,
                "im_hashtime": 1726801340,
                "im_is_ai": 0,
                "ibp_idx": null,
                "ibc_curator": null,
                "ibc_status": null,
                "ibc_applicant": null,
                "ibc_applicant_nick": null,
                "ic_name": "멤버십",
                "ic_name_eng": null,
                "node_id": 179,
                "ic_seq": 0,
                "ia_is_minimum": null,
                "ia_minimum": null,
                "ia_is_immediately": null,
                "ia_immediately": null,
                "ia_start_date": null,
                "ia_end_date": null,
                "ia_auction": null,
                "il_small_price": 0,
                "il_medium_price": 0,
                "il_large_price": 0,
                "il_extra_large_price": 0,
                "il_original_price": 100,
                "il_caution": "",
                "curator_nick": null,
                "originator_nick": "heetest7",
                "provider": 62,
                "provider_nick": "heetest7",
                "itemFileNumber": 25087,
                "last_update": "2024-09-20 12:02:21",
                "ibe_idx": null,
                "sellType": "license",
                "m_idx": 17762,
                "m_type": 2,
                "m_status": 1,
                "m_no": 62,
                "m_nick": "heetest7",
                "m_minimum": null,
                "m_immediately": null,
                "m_curator": null,
                "m_bid_no": null,
                "m_bid_price": null,
                "m_bid_nick": null,
                "m_bid_date": null,
                "m_first_order": 1,
                "m_auction_uuid": null,
                "m_license_option": "Original",
                "m_license_vertical": 1461,
                "m_license_horizontal": 945,
                "ibc_idx": null,
                "ic_idx": 179,
                "c_idx": 156,
                "profile": {
                    "provider": [
                        {
                            "a_idx": 70318,
                            "a_seq": 0,
                            "org_url": "http://testrealimage.minglemint.com/profile/62/MinglePass1727838279881.jpg",
                            "org_name": "photo-1727838275150.jpg"
                        }
                    ],
                    "originator": [
                        {
                            "a_idx": 70318,
                            "a_seq": 0,
                            "org_url": "http://testrealimage.minglemint.com/profile/62/MinglePass1727838279881.jpg",
                            "org_name": "photo-1727838275150.jpg"
                        }
                    ]
                },
                "ItemFile": {
                    "Item": [
                        {
                            "a_idx": 70292,
                            "a_seq": 0,
                            "org_url": "http://testrealimage.minglemint.com/item/25087/1726801337281wq0xpm505sn.png",
                            "org_name": "골프_남__.png"
                        }
                    ],
                    "Detail": [
                        {
                            "a_idx": 70294,
                            "a_seq": 0,
                            "org_url": "http://testrealimage.minglemint.com/item_detail/25087/1726801337782j8tzob5gmxa.png",
                            "org_name": "캐주얼_남__.png"
                        }
                    ],
                    "NFT": [
                        {
                            "a_idx": 70295,
                            "a_container_id": 25087,
                            "a_container_type": "itemNft",
                            "mb_no": 0,
                            "a_filename": "골프_남__.png",
                            "a_disk_filename": "1726801337597u0kb05pbh69.png",
                            "a_filesize": 1666507,
                            "a_file_extension": "",
                            "a_content_type": "image/png",
                            "a_digest": "",
                            "a_downloads": 0,
                            "description": "1",
                            "disk_directory": "mint",
                            "a_seq": 0,
                            "updated_at": "2024-09-20 12:02:21",
                            "created_at": "2024-09-20 12:02:21"
                        }
                    ]
                },
                "Like": {
                    "myLike": false
                },
                "Collection": {
                    "c_idx": 156,
                    "c_type": "basic",
                    "c_title": "골프왕",
                    "mb_no": 62,
                    "c_owner": 1,
                    "c_intro": "골프왕",
                    "c_total": 0,
                    "c_mingle_start": null,
                    "c_mingle_end": null,
                    "c_status": 1,
                    "created_at": "2024-09-20 12:01:31",
                    "updated_at": "2024-09-20 12:01:31",
                    "mb_id": "heetest7@dgmong.com",
                    "mb_nick": "heetest7",
                    "mb_is_use": 1,
                    "CollectionFiles": {
                        "MainFile": [
                            {
                                "a_idx": 70291,
                                "a_seq": 0,
                                "org_url": "http://testrealimage.minglemint.com/c_main/156/1726801291942ocvgbezk6st.png",
                                "org_name": "골프_남__.png"
                            }
                        ],
                        "BgFile": [
                            {
                                "a_idx": 70272,
                                "a_seq": 0,
                                "org_url": "http://testrealimage.minglemint.com/c_bg/156/1703061194312N04314A1Q7B64K1NTCIGJHGL5.png",
                                "org_name": "20220626_212412154_68899.png"
                            },
                            {
                                "a_idx": 70290,
                                "a_seq": 0,
                                "org_url": "http://testrealimage.minglemint.com/c_bg/156/1726801291609jp0c8owjeob.png",
                                "org_name": "비즈니스캐주얼_.png"
                            }
                        ]
                    },
                    "Likes": {
                        "cnt": 0,
                        "status": false
                    },
                    "item_cnt": 1,
                    "utility_cnt": 0,
                    "active_utility_cnt": 0
                },
                "Utility": {
                    "cnt": 0
                },
                "BlockChain": {
                    "canBePawned": "yes",
                    "canBeRented": "yes",
                    "canBeSold": "yes",
                    "createdTimestamp": "2024-09-20 12:02:21",
                    "divisibleNftParts": 0,
                    "imageUrl": "",
                    "nftCreator": "6e5be1e2-60b1-40f0-a393-3d1ab9083b71",
                    "nftHash": "fcd2dd2ef3b050401afc56ac5b6d22d55fcfd4c04ca1987ec6496b6d77caf234",
                    "nftId": "e53da56f-e365-468e-ba26-91d3e1b71d74",
                    "nftName": "골프왕아이템",
                    "nftOwner": "6e5be1e2-60b1-40f0-a393-3d1ab9083b71",
                    "nftOwners": [
                        {
                            "nftOwner": "6e5be1e2-60b1-40f0-a393-3d1ab9083b71",
                            "ownedPercentage": 100
                        }
                    ],
                    "price": 100,
                    "rightsRange": [
                        "royaltyEnabled"
                    ],
                    "royaltyEnabled": true,
                    "sellAgreement": [],
                    "type": "digital"
                },
                "ReserCnt": 0,
                "Hash": []
            }
        }
    },
    beforeRouterEnter() {
    },
    created() {

    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        handlerBeforeOpen(event) {
            $("body").addClass("modal-open")
            if (!util.isEmpty(event.params)) {
                this.utilityData = event.params.utilityData

                console.log(this.utilityData)

            }
        },
        handlerOpened() {

        },
        handlerBeforeClose() {
            $("body").removeClass("modal-open")
        },
        modalClose() {
            $("body").removeClass("modal-open")
            this.$modal.hide('utility-detail-modal');
        },


        moveRouter(data) {
            this.$router.push(`/item/${data}`)
        },

        returnThumbnail(data) {
            if (!util.isEmpty(data.ItemFile) && !util.isEmpty(data.ItemFile.Item)) {
                // return `${data.ItemFile.Item[0].org_url}?${this.setImageOptions(278, )}`;
                // return data.ItemFile.Item[0].org_url;
                return this.setFitWidthImg(data.ItemFile.Item, 278);
            }
        },

        returnCollectImage(data) {
            if (!util.isEmpty(data.Collection) && !util.isEmpty(data.Collection.CollectionFiles) && !util.isEmpty(data.Collection.CollectionFiles.MainFile)) {
                return `${data.Collection.CollectionFiles.MainFile[0].org_url}?${this.setImageOptions(90, 90, 'webp')}`;
            }
        },

        isMyLike(data) {
            return data?.Like?.myLike
        },

        movePage(data) {
            if (data.im_status === 2 || data.im_status === 5 || data.im_status === 8) {
                this.$router.push(`/premiummarket/${data.i_sku}`)
            } else {
                this.$router.push(`/openmarket/${data.i_sku}`);
            }

        },
        checkPremium(data) {
            if (data.im_status === 2 || data.im_status === 5 || data.im_status === 8) {
                return true
            }
            return false;
        },

        returnItemType(data) {
            let currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            if (data.i_status === 'auction') {
                if (data.ia_auction === 2) {
                    let diffTime = this.getDifferenceTime(data.ia_end_date, currentTime);
                    return diffTime;
                } else {
                    return this.$t('beforeAuction');
                }

            } else if (data.i_status === 'license') {
                return this.$t('license')
            } else if (data.i_status === 'pricetag') {
                return this.$t('fix_selling')
            } else {
                return this.$t('no_sell')
            }
        },
        returnCommas(x = 0) {
            if (util.isEmpty(x)) {
                return 0;
            }
            return util.Number.numFormat(x);
        },

        returnImg(data) {
            if (!util.isEmpty(data)) {
                return data.org_url;
            } else {
                return '';
            }
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
