<template>
    <event-detail-link v-if="detailLayout != ''" :detailLayout="detailLayout" :eventData="eventData"
    ></event-detail-link>
</template>

<script>
import eventUtil from "@/components/event/eventUtil";
import alertMixins from "@/mixins/alertMixins";
import filterMixins from "@/mixins/filterMixins";
import EventBus from "@/utils/event-bus";
import EventDetailLink from "@/components/event/link/EventDetailLink.vue"

const {filterConfig} = eventUtil;
export default {
    name: "EventDetailComponent",
    mixins: [alertMixins, filterMixins, eventUtil],
    components: {
        EventDetailLink
    },
    inject: [],
    provide() {
        return {
            setPage: this.setPage,
            setFilter: this.setFilter,
            setSort: this.setSort,
            toggleEventLike: this.toggleEventLike,
        }
    },
    props: {
        eventType: {
            default: () => '',
        },
        eventKey: '',
    },
    data() {
        return {
            detailLayout: '',
            getFunc: '',
            detailApiParamsSet: {},
            getKeyName: '',
            eventData: {},
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initConfig();
    },
    mounted() {
    },
    beforeUnmount() {

    },
    unmounted() {
    },
    computed: {},
    methods: {
        initConfig() {
            let config = this.returnEventConfig(this.eventType);
            this.getFunc = config.getFunc;
            this.detailApiParamsSet = config.detailApiParamsSet;
            this.getKeyName = config.getKeyName;
            this.getEvent();
        },
        getEvent() {
            EventBus.$emit('apiLoading', true);
            let params = JSON.parse(JSON.stringify(this.detailApiParamsSet));

            params[this.getKeyName] = this.eventKey;

            try {
                this.$api.$event[this.getFunc](params).then(res => res.Data.Result).then(res => {
                    if (res.Info.type !== 1 || res.List.length === 0) {
                        EventBus.$emit('apiLoading', false);
                        this.createAlert({
                            title: '',
                            content: this.$t('no_data_loading'),
                            btnText: this.$t('yes'),
                            hide: () => {
                                this.$router.back();
                            }
                        });
                    }
                    this.eventData = res.List[0];
                    EventBus.$emit('apiLoading', false);

                    let config = this.returnEventConfig(this.eventType);
                    this.detailLayout = config.detailLayout;
                })
            } catch (e) {
                console.log(e)
            }
        },
        toggleEventLike(data) {
            EventBus.$emit('apiLoading', true);
            let params = {e_idx: data.e_idx};
            try {
                this.$api.$event.toggleEventLikeBtn(params).then(res => res.Data.Result).then(res => {
                    if (res.Info.status === true) {
                        EventBus.$emit('apiLoading', false);
                        this.getEvent();
                    } else {
                        this.errorAlert(this.$t('fail_retry'));
                    }
                })
            }catch (e){
                console.log(e)
            }
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
