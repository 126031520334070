const eventConfig = {
    event: {
        filterLayout: 'EventFilterCategoryLayout',
        listLayout: 'EventListDefaultLayout',
        emptyLayout: 'EventEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        paginationType: 'pagination',
        getFunc: 'getEventHome',
        pageRow: 10,
        eventStatus: "none_empty_text",
        listApiParamSet: {
            ip: 1, f: 1,
            sp:10,
        },
        sortOptions: {keyName: "obt", orderName: "obs"},
    },
    eventSearch: {
        filterLayout: 'EventFilterInputLayout',
        listLayout: 'EventListDefaultLayout',
        emptyLayout: 'EventEmptyDefaultLayout',
        paginationLayout: 'PaginationNoneLayout',
        paginationType: 'pagination',
        getFunc: 'getEventHome',
        pageRow: 10,
        listApiParamSet: {
            ip: 1, f: 1,
        },
        isGetFirst: false,

        sortOptions: {keyName: "obt", orderName: "obs"},
    },
    eventSwiper: {
        filterLayout: 'EventFilterNoneLayout',
        listLayout: 'EventListSwiperLayout',
        emptyLayout: 'EventEmptyNoneLayout',
        paginationLayout: 'PaginationNoneLayout',
        paginationType: 'pagination',
        getFunc: 'getEventRecommend',
        pageRow: 10,
        listApiParamSet: {},
        sortOptions: {keyName: "obt", orderName: "obs"},
    },

    eventProgress: {
        filterLayout: 'EventFilterNoneLayout',
        listLayout: 'EventListProgressLayout',
        emptyLayout: 'EventEmptyHomeLayout',
        paginationLayout: 'PaginationNoneLayout',
        paginationType: 'pagination',
        getFunc: 'getEventOngoing',
        pageRow: 10,
        listApiParamSet: {
            ip:1,
            sp:20
        },
        keyName: 'no',
        eventStatus: 'progress'
    },
    eventUpcomming: {
        filterLayout: 'EventFilterNoneLayout',
        listLayout: 'EventListProgressLayout',
        emptyLayout: 'EventEmptyHomeLayout',
        paginationLayout: 'PaginationNoneLayout',
        paginationType: 'pagination',
        getFunc: 'getEventHome',
        pageRow: 10,
        listApiParamSet: {
            ip: 0,
            sp: 10,
            eper: 1,
        },
        keyName: 'no',
        eventStatus: 'intended'
    },
    eventEnd: {
        filterLayout: 'EventFilterNoneLayout',
        listLayout: 'EventListProgressLayout',
        emptyLayout: 'EventEmptyHomeLayout',
        paginationLayout: 'PaginationNoneLayout',
        paginationType: 'pagination',
        getFunc: 'getEventHome',
        pageRow: 10,
        listApiParamSet: {
            ip: 0,
            sp: 10,
            eper: 3,
        },
        keyName: 'no',
        eventStatus: 'terminated'
    },
    eventOngoing: {
        filterLayout: 'EventFilterDefaultLayout',
        listLayout: 'EventListDefaultLayout',
        emptyLayout: 'EventEmptyDefaultLayout',
        paginationLayout: 'PaginationNoneLayout',
        paginationType: 'pagination',
        getFunc: 'getEventHome',
        pageRow: 10,
        keyName: 'no',
        listApiParamSet: {
            eper: 2,
        },
    },
    eventUpcommingList: {
        filterLayout: 'EventFilterDefaultLayout',
        listLayout: 'EventListDefaultLayout',
        emptyLayout: 'EventEmptyDefaultLayout',
        paginationLayout: 'PaginationNoneLayout',
        paginationType: 'pagination',
        getFunc: 'getEventHome',
        pageRow: 10,
        keyName: 'no',
        listApiParamSet: {
            eper: 1,
        },
    },
    eventPastList: {
        filterLayout: 'EventFilterDefaultLayout',
        listLayout: 'EventListDefaultLayout',
        emptyLayout: 'EventEmptyDefaultLayout',
        paginationLayout: 'PaginationNoneLayout',
        paginationType: 'pagination',
        getFunc: 'getEventHome',
        pageRow: 10,
        keyName: 'no',
        listApiParamSet: {
            eper: 3,
        },
    },

    eventMyDirector: {
        filterLayout: 'EventFilterDefaultLayout',
        listLayout: 'EventListDefaultLayout',
        emptyLayout: 'EventEmptyDefaultLayout',
        paginationLayout: 'PaginationNoneLayout',
        paginationType: 'pagination',
        getFunc: 'getEventHome',
        pageRow: 10,
        listApiParamSet: {
            f: 1
        },
        sortOptions: {keyName: "obt", orderName: "obs"},
    },

    eventDirector: {
        filterLayout: 'EventFilterDirectorLayout',
        listLayout: 'EventListDefaultLayout',
        emptyLayout: 'EventEmptyDefaultLayout',
        paginationLayout: 'PaginationNoneLayout',
        paginationType: 'pagination',
        getFunc: 'getEventHome',
        pageRow: 10,
        eventStatus: 'none_empty_text',
        listApiParamSet: {
            f: 1
        },
        keyName: 'no',
        sortOptions: {keyName: "obt", orderName: "obs"},
    },
    eventDetail: {
        detailLayout: 'EventDetailLayout',
        getFunc: 'getEventHome',
        detailApiParamsSet: {
            f: 1,
            d: 1,
            st: 1,
        },
        getKeyName: 'id',

    },

}


export default {
    methods: {
        returnEventConfig(type) {
            return eventConfig[type];
        },
    },
    filterConfig: {
        ena: {
            type: 'query',
            classname: 'searchInput',
        },
        eca: {
            type: 'match',
            classname: 'filterCategory',
        },
        nperArr: {
            type: 'match',
            classname: 'usest',
        },
        eperArr: {
            type: 'match',
            classname: 'sellst',
        },
        stpr: {
            type: 'match',
            classname: 'filterStartPrice'
        },
        enpr: {
            type: 'match',
            classname: 'filterEndPrice'
        },
        eper: {
            type: 'match',
            classname: 'activeEvent'
        },
        obt: {
            type: 'match',
            classname: 'eventSort'
        },
    }
}
