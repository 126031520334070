<template>
    <svg
        ref="svg"
        xmlns="http://www.w3.org/2000/svg"
        class="svg-ticket" 
        :width="svgWidth"
        :height="svgHeight">
        <path ref="pathLeft"
              :d="`M ${radius} 0
                H ${leftSvgWidth - radius}
                A ${radius} ${radius} 0 0 0 ${leftSvgWidth} ${radius}
                V ${svgHeight - radius}
                A ${radius} ${radius} 0 0 0 ${leftSvgWidth - radius} ${svgHeight}
                H ${radius}
                A ${radius} ${radius} 0 0 1 0 ${svgHeight - radius}
                V ${radius}
                A ${radius} ${radius} 0 0 1 ${radius} 0
                Z`"
              :fill="ticketLeftColor"/>
        <path ref="pathRight"
              :d="`
                M ${leftSvgWidth + radius} 0
                H ${svgWidth - radius}
                A ${radius} ${radius} 0 0 1 ${svgWidth} ${radius}
                V ${svgHeight - radius}
                A ${radius} ${radius} 0 0 1 ${svgWidth - radius} ${svgHeight}
                H ${leftSvgWidth + radius}
                A ${radius} ${radius} 0 0 0 ${leftSvgWidth} ${svgHeight - radius}
                V ${radius}
                A ${radius} ${radius} 0 0 0 ${leftSvgWidth + radius} 0
                Z
            `" :fill="ticketRightColor"/>
    </svg>

</template>

<script>


export default {
    name: "TicketImage",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        svgWidth: {
            default: () => window.innerWidth
        },
        svgHeight: {
            default: () => 135
        },
        leftSvgWidth:{
            default: () => 48
        },
        ticketLeftColor:{
            default: () => '#007B94'
        },
        ticketRightColor: {
            default: () => '#FFF'
        },
    },
    data() {
        return {
            radius: 10,
        }
    },
    beforeRouterEnter() {
    },
    created() {

    },
    mounted() {

    },
    beforeUnmount() {
    },
    unmounted() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>