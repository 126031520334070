<template>
    <div class="container-filter">
        <el-input
            class="input-filter" :placeholder="$t('event_search_placeholder')" v-model="searchInput"
            @keypress.enter.native="setFilterFunc('ena', searchInput)" @input="setFilterFunc('ena', searchInput)">
            <template #suffix>
                <button class="btn-input-filter" v-if="searchInput.length === 0"></button>
                <button class="btn-input-filter close" v-else @click="reset"></button>
            </template>
        </el-input>
    </div>
</template>


<script>

// import eventUtil from "@/components/event/eventUtil.js";
import EventBus from "@/utils/event-bus";

// const {filterConfig} = eventUtil;
export default {
    name: "EventFilterInputLayout",
    mixins: [],
    inject: ['setFilter'],
    components: {},
    props: {
        filterData: {
            default: () => {
                return {}
            }
        },
    },
    data() {
        return {
            searchInput: '',
            filterCategory: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        EventBus.$on('setEventFilterModal', this.setEventFilterModal);
    },
    beforeUnmount() {
        EventBus.$off('setEventFilterModal');
    },
    unmounted() {
    },
    computed: {},
    methods: {
        reset() {
            this.searchInput = '';
            this.setFilterFunc('ena', this.searchInput)
        },
        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },
        setFilterCategory(key) {
            if (this.filterCategory != key) {
                this.filterCategory = key;
                this.setFilterFunc('eca', this.filterCategory);
            } else {
                this.filterCategory = '';
                this.setFilterFunc('eca', this.filterCategory);
            }
        },
        showFilter() {
            this.$modal.show('event-filter-modal');
        },
        setEventFilterModal(key) {
            this.setFilterFunc('nperArr', key.nperArr);
        },
    },
    watch: {},
}
</script>
