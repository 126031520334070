<template>
    <div style="display: contents">
    </div>
</template>

<script>
export default {
    name: "EventEmptyNoneLayout",
    mixins: [],
    components: {},
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeUnmount() {
    },
    unmounted() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>
