<template>
    <modal
        name="event-normal-filter-modal"
        class="event-modal"
        :width="'100%'"
        :height="'auto'"
        transition="modal-right"
        v-model="show"
        :pivotY="0.5"
        @before-open="handlerBeforeOpen"
        @before-close="handlerBeforeClose"
        @closed="handlerClosed"
    >
        <div class="box-flex pb20">
            <div class="event-modal-header">{{ $t(returnTitle()) }}</div>
            <button class="icon-close-event" @click="modalClose()"></button>
        </div>
        <div class="wrap-filter" v-if="titleData == 'event'">
            <el-checkbox-group :class="'usest'" v-model="useSelect">
                <el-checkbox class="event-checkbox-common" :value="'1'" :label="'1'">{{ $t('intended') }}</el-checkbox>
                <el-checkbox class="event-checkbox-common" :value="'2'" :label="'2'">{{ $t('progress') }}</el-checkbox>
                <el-checkbox
                    class="event-checkbox-common"
                    :value="'3'"
                    :label="'3'"
                >{{ $t('terminated') }}</el-checkbox>
            </el-checkbox-group>
        </div>
        <div class="wrap-filter" v-if="titleData == 'nticket'">
            <el-checkbox-group :class="'sellst'" v-model="dealSelect">
                <el-checkbox class="event-checkbox-common" :value="'1'" :label="'1'">
                    {{
                    $t('stay_buy')
                    }}
                </el-checkbox>
                <el-checkbox class="event-checkbox-common" :value="'2'" :label="'2'">
                    {{
                    $t('selling')
                    }}
                </el-checkbox>
                <el-checkbox class="event-checkbox-common" :value="'3'" :label="'3'">
                    {{
                    $t('sell_end')
                    }}
                </el-checkbox>
            </el-checkbox-group>
        </div>
        <button
            class="btn-common mt30"
            type="button"
            @click="search()"
        >{{ $t('btn_settings_completion') }}</button>
    </modal>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import EventBus from "@/utils/event-bus";
import util from "@/mixins/util";
import eventUtil from "@/components/event/eventUtil";

const { filterConfig } = eventUtil;
export default {
    name: "EventNormalFilterModal",
    mixins: [],
    components: {
        PageHeader,
    },
    inject: [],
    provide() {
        return {};
    },
    props: {
    },
    data() {
        return {
            show: false,
            showModal: true,
            filterData: {},
            useSelect: [],
            dealSelect: [],
            paginationData: { default: { page: 1, size: 10, total: 0 } },
            errorMsg: "",
            isBtnAvailable: false,
            titleData: '',
        };
    },
    beforeRouterEnter() {},
    created() {},
    mounted() {},
    beforeUnmount() {},
    unmounted() {},
    computed: {},
    methods: {
       
        handlerBeforeOpen(event) {
            if (
                !util.isEmpty(event.params) &&
                !util.isEmpty(event.params.filterData)
            ) {
                this.filterData = event.params.filterData;
            }

            if (
                !util.isEmpty(event.params) &&
                !util.isEmpty(event.params.titleData)
            ) {
                this.titleData = event.params.titleData;
            }


            this.useSelect = this.filterData.usest;
            this.dealSelect = this.filterData.sellst;
        },
        handlerBeforeClose() {
            this.resetFilter();
        },
        handlerClosed() {},
        resetFilter() {
            this.useSelect = [];
            this.dealSelect = [];
        },
        search() {
            let data = {};
            data.usest = this.useSelect;
            data.sellst = this.dealSelect;
            EventBus.$emit("setEventFilterModal", data);
            this.modalClose();
        },
        modalClose() {
            this.$modal.hide("event-normal-filter-modal");
        },
    
        setDealStatusFilter(value) {
            if (!this.dealSelect.includes(value)) {
                this.dealSelect.push(value);
            } else {
                this.dealSelect = this.dealSelect.filter((el) => el !== value);
            }
        },

        returnTitle() {
            if(this.titleData == 'event') {
                return 'event_status';
            } else if (this.titleData == 'nticket') {
                return 'nticekt_sell_status';
            }
        }
    },
    watch: {},
};
</script>

<style scoped>
</style>
