<template>
    <component :is="loader" v-if="loader" :eventData="eventData"></component>
</template>

<script>
import {defineAsyncComponent} from "vue";

export default {
    name: "EventDetailLink",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        detailLayout: {
            default: () => '',
        },
        eventData: {
            default: () => {},
        },
    },
    data() {
        return {
            component: null,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeUnmount() {
    },
    unmounted() {
    },
    computed: {
        loader() {
            if (!this.detailLayout) {
                return defineAsyncComponent(() => import('@/components/event/layout/detail/EventDetailLayout.vue'));
            }
            return defineAsyncComponent(() =>  import(`@/components/event/layout/detail/${this.detailLayout}.vue`));
        },
    },
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>
