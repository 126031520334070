<template>
    <component :is="loader" v-if="loader" :filterData="filterData" :eventCategoryList="eventCategoryList" :sortData="sortData"></component>
</template>

<script>
import {defineAsyncComponent} from "vue";

export default {
    name: "EventFilterLink",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        filterLayout: {
            default: () => ''
        },
        filterData: {},
        eventCategoryList: {
            default : () =>[]
        },
        sortData: {},
    },
    data() {
        return {
            component: null,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeUnmount() {
    },
    unmounted() {
    },
    computed: {
        loader() {
            if (!this.filterLayout) {
                return defineAsyncComponent(() => import('@/components/event/layout/eventList/filter/EventFilterDefaultLayout.vue'));
            }
            return defineAsyncComponent(() => import(`@/components/event/layout/eventList/filter/${this.filterLayout}.vue`))
        },
    },
    methods: {},
    watch: {},
}
</script>

<style scoped>

</>
  